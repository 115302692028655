export const detectOS = (): string => {
  const isSSR = typeof window === "undefined";
  if (isSSR) return "unknown";
  const userAgent = navigator?.userAgent;
  let os;

  if (/Windows/i.test(userAgent)) {
    os = "Windows";
  } else if (/Macintosh|Mac OS X/i.test(userAgent)) {
    os = "Mac OS";
  } else if (/Linux/i.test(userAgent)) {
    os = "Linux";
  } else if (/Android/i.test(userAgent)) {
    os = "Android";
  } else if (/iOS/i.test(userAgent)) {
    os = "iOS";
  } else {
    os = "unknown";
  }

  return os;
};

// Create function to detect if the user is on mac or ios or not
export const isMac = (): boolean => {
  const os = detectOS();
  return os === "Mac OS" || os === "iOS";
};
