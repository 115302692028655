import { useState, useEffect } from "react";
import DOMPurify from "dompurify";

const isSSR = typeof window === "undefined";

/**
 * Sanitize HTML string (only on client side)
 * @param html HTML string to sanitize
 * @returns Sanitized HTML string
 */
export const useSanitized = (html: string | undefined) => {
  const [sanitizedContent, setSanitizedContent] = useState({
    __html: isSSR ? html : DOMPurify.sanitize(html),
  });

  useEffect(() => {
    if (isSSR) return;
    const sanitized = DOMPurify.sanitize(html);
    setSanitizedContent({ __html: sanitized });
  }, [html]);

  return sanitizedContent;
};
