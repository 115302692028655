// TODO: Move this to a util file
export interface PriceDisplayInfo {
  name: string;
  interval: string;
  pricePerInterval: string;
  priceWithInterval: string;
  priceMonth: string;
  priceYear: string;
  intervalInfo?: string;
  pricePerIntervalNumber: number;
}

export const getPriceDisplayInfo = (
  interval: "month" | "year",
  unit_amount: number
): PriceDisplayInfo => {
  const intervalName = interval === "month" ? "Monat" : "Jahr";

  const pricePerIntervalNumber = unit_amount / 100;

  const pricePerInterval = Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(pricePerIntervalNumber);

  const priceMonth = Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(interval === "month" ? unit_amount / 100 : unit_amount / 12 / 100);

  const priceYear = Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(
    interval === "month" ? (unit_amount * 12) / 100 : unit_amount / 100
  );

  const info: PriceDisplayInfo = {
    name:
      interval === "month" ? "Monatliche Abrechnung" : "Jährliche Abrechnung",
    interval: intervalName,
    pricePerInterval: pricePerInterval,
    priceWithInterval: pricePerInterval + "/" + intervalName,
    priceMonth: priceMonth,
    priceYear: priceYear,
    // Mention the full price for the year if the interval is yearly
    intervalInfo:
      interval === "month"
        ? undefined
        : `Jährliche Abrechnung von ${priceYear}`,
    pricePerIntervalNumber: pricePerIntervalNumber,
  };

  return info;
};
