import { BookOpenIcon, PlusCircleIcon } from "@heroicons/react/20/solid";
import ExportedImage from "next-image-export-optimizer";
import RevealContainer from "./RevealContainer";
import { motion } from "framer-motion";

export const contentInfo: {
  name: string;
  description: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  content?: string;
}[] = [
  {
    name: "Zivilrecht",
    description:
      "Zivilrechtliches Allgemeinwissen - Allgemeiner Teil des BGB -Schuldrecht Allgemeiner Teil - Vertragliche Schuldverhältnisse - Gesetzliche Schuldverhältnisse - Allgemeines Sachenrecht - Mobiliarsachenrecht - Immobiliarsachenrecht",
    icon: BookOpenIcon,
    content:
      "35+ Lernpfade mit 1000+ Karten und Wiederholungsfragen sowie 450+ Multiple-Choice Fragen",
  },
  {
    name: "Strafrecht",
    description:
      "Allgemeiner Teil des StGB - Nichtvermögensdelikte - Vermögensdelikte - Strafprozessrecht",
    icon: BookOpenIcon,
    content:
      "25 Lernpfade mit 450+ Karten und Wiederholungsfragen, Multiple-Choice Fragen sind bald verfügbar",
  },
  {
    name: "Öffentliches Recht",
    description: "Bald verfügbar", // "Staatsorganisationsrecht - Grundrechte - Verfassungsprozessrecht - Allgemeines Verwaltungsprozessrecht - Allgemeines Verwaltungsverfahrensrecht - Besonderes Verwaltungsrecht - Europarecht",
    icon: BookOpenIcon,
    content: "Bald verfügbar",
  },
  {
    name: `Ratgeber “How-to-Jura”`,
    description: "Zitierratgeber, Aufbautipps und Gutachtenstil-Trainer",
    icon: PlusCircleIcon,
    content: "Bonusinhalte mit Wissen zum Zitieren, Aufbau und Gutachtenstil",
  },
  {
    name: "Juristisches Allgemeinwissen",
    description:
      "Arbeit im Gutachten - Rechts- und Gerichtswesen -Rechtsgeschichte",
    icon: PlusCircleIcon,
    content:
      "Bonusinhalte zur Arbeiten im Gutachten, Rechts- und Gerichtswesen, Rechtsgeschichte",
  },
];

export default function Features3() {
  return (
    <div
      className="relative isolate overflow-hidden bg-white py-24 dark:bg-gray-900 sm:py-32"
      id="inhalte"
    >
      <svg
        className="absolute inset-0 -z-10 h-full w-full stroke-slate-900/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)] dark:stroke-white/10"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <svg
          x="50%"
          y={-1}
          className="overflow-visible fill-slate-200/20 dark:fill-gray-800/20"
        >
          <path
            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect
          width="100%"
          height="100%"
          strokeWidth={0}
          fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
        />
      </svg>
      <div className="absolute inset-x-0 top-0 bg-gradient-to-b from-white pt-[7%] dark:from-gray-900" />

      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <RevealContainer className="lg:ml-auto lg:pt-4 lg:pl-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-orange-500">
                Welche Inhalte bietet Jurahilfe.de?
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                Umfassende Inhalte für dein Jurastudium
              </p>
              <p className="mt-6 text-lg leading-6 text-gray-600 dark:text-slate-300 sm:leading-8">
                Alles Wissen, das du vom ersten Semester bis zum Examem
                benötigst, komprimiert in einer App, die Kompaktskripten,
                Schemasammlungen, Karteikarten, Nachschlagewerk und Falltraining
                vereint. Der Lernstoff wird ergänzt durch die hilfreichen
                “How-to-Jura”-Ratgeber, um optimal durchs Studium zu finden. In
                Zukunft folgen ansprechende Lernvideos und weitere
                Zusatzinhalte.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 dark:text-slate-300 lg:max-w-none">
                {contentInfo.map((content) => (
                  <div key={content.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900 dark:text-white">
                      <content.icon
                        className="absolute top-1 left-1 h-5 w-5 text-orange-500"
                        aria-hidden="true"
                      />
                      {content.name}
                    </dt>{" "}
                    <dd className="inline">{content.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </RevealContainer>
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.6 }}
            className="flex items-center justify-end lg:order-first"
          >
            <ExportedImage
              src="/images/app_content_1_light.png"
              alt="Übersicht über die Inhalte und Fortschritt im Zivilrecht, Strafrecht und Öffentliches Recht im Light-Mode"
              className="block w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 dark:hidden sm:w-[57rem]"
              width={2432}
              height={1442}
            />
            <ExportedImage
              src="/images/app_content_1_dark.png"
              alt="Übersicht über die Inhalte und Fortschritt im Zivilrecht, Strafrecht und Öffentliches Recht im Dark-Mode"
              className="hidden w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 dark:block sm:w-[57rem]"
              width={2432}
              height={1442}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
}
