import { useEffect, useState, useCallback } from "react";
import { collection, onSnapshot, query } from "@firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

import useStore from "@hooks/useStore";
import { firestore } from "@utils/firebase/firebase-init";
import { auth } from "@utils/firebase/firebase-initAuth";
import { ContentLimit, StripeProduct, SubscriptionStatus } from "@types";

/**
 * Custom hook to initialize the Zustand store with data from Firebase Firestore.
 *
 * This hook performs the following actions:
 * 1. Fetches Stripe products from the Firestore 'products' collection and stores them in the Zustand store.
 * 2. Sets up an authentication state observer to monitor user login status.
 * 3. Fetches user settings from the Firestore 'customers/{uid}/appSettings' collection and stores them in the Zustand store.
 * 4. Fetches user subscription status from the Firestore 'customers/{uid}/subscriptions' collection and stores it in the Zustand store.
 * 5. Sets the Zustand store and local state to 'initialized' once all data fetching is complete.
 *
 * The hook returns a boolean indicating whether the store has been initialized.
 *
 * @returns {boolean} isInitialized - A boolean indicating if the Zustand store has been initialized.
 */
export const useInitializeStore = () => {
  const { set, setInitialized } = useStore((state) => ({
    set: state.set,
    setInitialized: state.setInitialized,
  }));
  const [isInitialized, setIsInitialized] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const [stripeProducts, setStripeProducts] = useState<
    Record<string, StripeProduct>
  >({});

  const processSubscription = useCallback(
    (querySnap, stripeProducts) => {
      let status: SubscriptionStatus;
      let contentLimit: ContentLimit;
      let subscriptionId: string;
      let productId: string;
      let trialEligible = false;

      let intervalNormalized: string;

      const allStatus = querySnap.docs.map((doc) => doc.data().status);

      if (querySnap.empty) {
        status = "free";
        trialEligible = true;
      } else if (
        allStatus.includes("active") ||
        allStatus.includes("trialing")
      ) {
        const activeSubscriptionSnap = querySnap.docs.find(
          (doc) =>
            doc.data().status === "active" || doc.data().status === "trialing"
        );

        const activeSubscription = activeSubscriptionSnap?.data();

        const relatedProduct = stripeProducts[activeSubscription?.product?.id];

        status = "pro";
        contentLimit = relatedProduct?.metadata.contentLimit;
        productId = relatedProduct?.id;
        subscriptionId = activeSubscriptionSnap?.id;
        const interval = activeSubscription?.items[0]?.plan?.interval;
        intervalNormalized = interval === "month" ? "monthly" : "annually";
      } else {
        status = "free";
      }

      console.log(
        "Setting user Subscription settings",
        status,
        "Content limit",
        contentLimit
      );

      set((state) => ({
        user: {
          ...state.user,
          subscriptionStatus: status,
          subscription: {
            ...state.user?.subscription,
            status: status,
            contentLimit: contentLimit,
            trialEligible: trialEligible,
            id: subscriptionId,
            productId: productId,
            interval: intervalNormalized,
          },
          trialEligible: trialEligible,
        },
      }));
    },
    [set]
  );

  useEffect(() => {
    console.log("Initializing store");

    let unsubscribeProducts;
    let unsubscribeSettings;
    let unregisterAuthObserver;

    const initializeStore = async () => {
      // Get stripe products from products collection and store in store
      const productsRef = collection(firestore, "products");
      unsubscribeProducts = onSnapshot(productsRef, (querySnap) => {
        if (querySnap.empty) {
          console.log("No matching documents.");
          return;
        }
        const products = {};
        querySnap.forEach((doc) => {
          products[doc.id] = {
            ...doc.data(),
            id: doc.id,
          };
        });

        setStripeProducts(products);
        set(() => ({
          stripeProducts: products,
        }));
      });

      unregisterAuthObserver = onAuthStateChanged(auth, (user) => {
        setCurrentUser(user);
        if (user) {
          const settingsRef = collection(
            firestore,
            "customers",
            user.uid,
            "appSettings"
          );
          unsubscribeSettings = onSnapshot(settingsRef, (querySnap) => {
            if (querySnap.empty) {
              console.log("No matching documents.");
              return;
            }
            const newSettings = {};
            querySnap.forEach((doc) => {
              newSettings[doc.id] = doc.data();
            });
            console.log("Settings category", newSettings);

            set((state) => ({
              user: {
                ...state.user,
                settings: newSettings,
              },
            }));
          });
        }
      });
    };

    initializeStore()
      .then(() => {
        // Set store as initialized
        setInitialized(true);
        // Set hook as initialized
        setIsInitialized(true);
        console.log("✅ Store initialized");
      })
      .catch((error) => {
        console.error("Error initializing store", error);
      });

    return () => {
      // Clean up listeners
      console.log("Unregistering listeners");
      if (unsubscribeProducts) unsubscribeProducts();
      if (unsubscribeSettings) unsubscribeSettings();
      if (unregisterAuthObserver) unregisterAuthObserver();
    };
  }, [set, setInitialized, processSubscription]);

  // Process subscription in separate effect to make sure
  // all required data is available
  useEffect(() => {
    if (!currentUser) return;

    const subscriptionRef = collection(
      firestore,
      "customers",
      currentUser.uid,
      "subscriptions"
    );

    const unsubscribeSubscriptions = onSnapshot(
      query(subscriptionRef),
      (querySnap) => {
        processSubscription(querySnap, stripeProducts);
      }
    );

    return () => {
      if (unsubscribeSubscriptions) unsubscribeSubscriptions();
    };
  }, [currentUser, stripeProducts, processSubscription]);

  return isInitialized;
};
