import clsx from "clsx";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";

const FeatureBox = ({
  title,
  description,
  Icon,
  pro,
  highlighted,
}: {
  title: string;
  description?: string;
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  pro?: string;
  highlighted?: boolean;
}) => (
  <div className="flex h-full flex-col">
    <div
      className={clsx(
        highlighted
          ? "border-orange-500"
          : "border-slate-300 dark:border-slate-700",
        "relative flex h-full flex-col gap-y-2 rounded-lg border py-4 px-3 text-center"
      )}
    >
      <div className="absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2  bg-white px-4 dark:bg-gray-800">
        <Icon className="h-5 w-5 text-orange-600" />
      </div>
      <div className="flex items-center justify-center gap-2">
        <div className="text-base font-semibold text-orange-500">{title}</div>
      </div>
      {description && (
        <p className="flex-shrink flex-grow text-sm text-slate-500 dark:text-slate-300">
          {description}
        </p>
      )}
      {pro && (
        <div className="flex flex-grow flex-col items-center justify-center md:mt-2">
          <div className="highlight-gradient-text rounded-md py-2 px-2 text-center text-white sm:py-3">
            <div className="text-sm font-medium leading-4 sm:font-semibold">
              <ArrowRightCircleIcon className="-mt-0.5 mr-1 inline-block h-4 w-4 shrink-0 text-orange-600" />
              <span>{pro}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
);

export default FeatureBox;
