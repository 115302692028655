import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import clsx from "clsx";

import Button from "@components/shared/Button";
import { getPriceDisplayInfo } from "@utils/getPriceDisplayInfo";

const PaymentInstructionDisplay = ({
  loading,
  handleSubscribe,
  product,
  trialEligible,
}) => {
  const [selectedPrice, setSelectedPrice] = useState(
    product?.prices[1]?.id || product?.prices[0]?.id
  );
  return (
    <div className="text-slate-900">
      {/* <div className="mb-3 text-sm">
          <p className="font-normal text-slate-500">Bitte auswählen</p>
        </div> */}
      <RadioGroup value={selectedPrice} onChange={setSelectedPrice}>
        <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
        <div className="space-y-2">
          {product.prices.map((price) => {
            const planInfo = getPriceDisplayInfo(
              price.interval,
              price.unit_amount
            );
            return (
              <RadioGroup.Option
                key={price.id}
                value={price.id}
                className={({ active }) =>
                  clsx(
                    active
                      ? "border-orange-500 ring-2 ring-orange-500"
                      : "border-gray-300",
                    "relative block cursor-pointer rounded-lg border bg-white px-6 py-4 text-left shadow-sm focus:outline-none dark:bg-slate-700 sm:flex sm:justify-between"
                  )
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex flex-col justify-between text-sm">
                      <RadioGroup.Label
                        as="span"
                        className="font-medium text-gray-900 dark:text-slate-100"
                      >
                        {planInfo.name}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className="text-gray-500 dark:text-slate-300"
                      >
                        <span className="block sm:inline">
                          {planInfo.priceMonth} /Monat
                        </span>{" "}
                        {planInfo.intervalInfo && (
                          <>
                            <span
                              className="hidden sm:mx-1 sm:inline"
                              aria-hidden="true"
                            >
                              &middot;
                            </span>{" "}
                            <span className="block bg-gradient-to-br from-orange-400 to-orange-500 bg-clip-text font-semibold text-transparent sm:inline ">
                              {planInfo.intervalInfo}
                            </span>
                          </>
                        )}
                      </RadioGroup.Description>
                    </div>
                    <RadioGroup.Description
                      as="span"
                      className="mt-2 flex text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-right"
                    >
                      <span className="text-lg font-semibold text-gray-900 dark:text-slate-100">
                        {planInfo.pricePerInterval}
                      </span>
                      <span className="ml-1 text-gray-500 dark:text-slate-300 sm:ml-0">
                        /{planInfo.interval}
                      </span>
                    </RadioGroup.Description>
                    <span
                      className={clsx(
                        active ? "border" : "border-2",
                        checked
                          ? "border-orange-500"
                          : "border-transparent dark:border-slate-800",
                        "pointer-events-none absolute -inset-px rounded-lg "
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}
              </RadioGroup.Option>
            );
          })}
        </div>
      </RadioGroup>

      <div className="mt-6">
        <div className="rounded-md shadow">
          <Button
            loading={loading}
            type="upgrade"
            onClick={() => handleSubscribe(selectedPrice)}
          >
            <span className="font-semibold">
              {trialEligible ? "14 Tage kostenlos testen" : "Pro freischalten"}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PaymentInstructionDisplay;
