import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import {
  getFirebaseConfig,
  getRecaptchaProviderConfig,
} from "./firebase-config";

console.log("Init Firebase");
export const firebaseApp = initializeApp(getFirebaseConfig());

export const firestore = getFirestore(firebaseApp);
export const functions = getFunctions(firebaseApp);
functions.region = "europe-west3";

async function connectEmulators() {
  // lazy load connection to emulators

  if (
    typeof window !== "undefined" &&
    window.location.hostname === "localhost" &&
    process.env.NEXT_PUBLIC_USE_FIREBASE_EMULATORS === "true"
  ) {
    const { connectFirestoreEmulator } = await import("firebase/firestore");
    const { connectFunctionsEmulator } = await import("firebase/functions");

    // Set the environment variables for firestore and functions
    process.env.FIRESTORE_EMULATOR_HOST = "localhost:8080";

    connectFirestoreEmulator(firestore, "localhost", 8080);
    connectFunctionsEmulator(functions, "localhost", 5001);
  }
}
connectEmulators();

const initAppCheck = async () => {
  try {
    if (
      typeof window !== "undefined" &&
      window.location.hostname !== "localhost" &&
      getRecaptchaProviderConfig() !== ""
    ) {
      const { initializeAppCheck, ReCaptchaV3Provider } = await import(
        "firebase/app-check"
      );
      initializeAppCheck(firebaseApp, {
        provider: new ReCaptchaV3Provider(getRecaptchaProviderConfig()),

        // Optional argument. If true, the SDK automatically refreshes App Check
        // tokens as needed.
        isTokenAutoRefreshEnabled: true,
      });
    }
  } catch (error) {
    console.log(error);
  }
};
if (typeof window !== "undefined") {
  if (document.readyState !== "loading") {
    // The event DOMContentLoaded was already fires and therefore we initialize
    // AppCheck without attaching it to the event listener for DOMContentLoaded
    if (window.location.pathname === "/") {
      // On the landing page, we delay the initialization of AppCheck
      setTimeout(() => {
        initAppCheck();
      }, 7000);
    } else {
      initAppCheck();
    }
  } else {
    document.addEventListener("DOMContentLoaded", function () {
      if (window.location.pathname === "/") {
        // On the landing page, we delay the initialization of AppCheck
        setTimeout(() => {
          initAppCheck();
        }, 7000);
      } else {
        initAppCheck();
      }
    });
  }
}
