import "../styles/globals.css";
//import drawer css
import "react-modern-drawer/dist/index.css";

import Head from "next/head";
import PlausibleProvider from "next-plausible";
import { ThemeProvider } from "next-themes";
import { Inter } from "@next/font/google";
import CookieBanner from "@components/cookieBanner/CookieBanner";
import Analytics from "@components/analytics/Analytics";
import Logo from "@components/shared/Logo";
import NavigationContainer from "@components/layout/NavigationContainer";
import DoubleConfirmModal from "@components/shared/DoubleConfirmModal";

import { useMigrations } from "@hooks/firebase/useMigrations";
import { useInitializeStore } from "@hooks/useInitializeStore";

const inter = Inter({
  subsets: ["latin"],
  variable: "--font-inter",
});

function MyApp({ Component, pageProps, router }) {
  const isInitialized = useInitializeStore();

  useMigrations(isInitialized);

  if (!isInitialized) {
    return (
      <div className="flex h-screen flex-col items-center justify-center gap-4 bg-gray-900 text-gray-400">
        {/* Animate an infinite spinner that starts slow turns very fast and slows down again with framer motion */}
        <div className="animate-pulse">
          <Logo size="large" />
        </div>
      </div>
    );
  }

  return (
    <>
      <Head>
        <title>Jurahilfe.de | Lerne einfach Jura</title>
        <meta
          name="description"
          content="Jura-Training kompakt, verlinkt und interaktiv."
        />
        <link rel="alternate" hrefLang="de" href="https://rep.jurahilfe.de" />
        <link
          rel="alternate"
          hrefLang="x-default"
          href="https://rep.jurahilfe.de"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="theme-color" content="#0f172a"></meta>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />

        {/* Social sharing images */}
        <meta property="og:url" content="https://rep.jurahilfe.de" />
        <meta property="og:title" content="Jurahilfe.de | Lerne einfach Jura" />
        <meta
          property="og:description"
          content="Jura-Training kompakt, verlinkt und interaktiv."
        />
        <meta
          property="og:image"
          content="https://rep.jurahilfe.de/sharing-image.png"
        />
        <meta property="og:type" content="website" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:text:title"
          content="Jurahilfe.de | Lerne einfach Jura"
        />
        <meta
          name="twitter:description"
          content="Jura-Training kompakt, verlinkt und interaktiv."
        />
        <meta
          name="twitter:image"
          content="https://rep.jurahilfe.de/sharing-image.png"
        />
        <script>
          {`(function(d, w, c) {
                w.SibConversationsID = '636bd4546383d8497e2d0abd';
                w[c] = w[c] || function() {
                    (w[c].q = w[c].q || []).push(arguments);
                };
                var s = d.createElement('script');
                s.async = true;
                s.src = 'https://conversations-widget.sendinblue.com/sib-conversations.js';
                if (d.head) d.head.appendChild(s);
            })(document, window, 'SibConversations');`}
        </script>
      </Head>
      <style jsx global>{`
        html {
          font-family: ${inter.style.fontFamily};
        }
      `}</style>
      <PlausibleProvider domain="jurahilfe.de,rep.jurahilfe.de">
        <ThemeProvider attribute="class" defaultTheme="dark" enableSystem>
          {router.state?.route !== "/" &&
          router.state?.route !== "/login" &&
          router.state?.route !== "/registrieren" &&
          router.state?.route !== "/reset-password" ? (
            <NavigationContainer>
              <Component {...pageProps} />
            </NavigationContainer>
          ) : (
            <NavigationContainer>
              <Component {...pageProps} />
            </NavigationContainer>
          )}

          <DoubleConfirmModal />

          <CookieBanner
            privacyPolicyLink={"https://www.jurahilfe.de/privacy"}
            showStatistic={
              process.env.NEXT_PUBLIC_ANALYTICS_PROVIDER === "plausible"
                ? false
                : true
            }
            showMarketing={false}
            showExternalMedia={false}
          />
        </ThemeProvider>
      </PlausibleProvider>
      <Analytics />
    </>
  );
}

export default MyApp;
