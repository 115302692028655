import { CardPathInfo, CardWithRef } from "@types";
import { firestore } from "@utils/firebase/firebase-init";

import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";

export const useLinkedCards = (tag: string): [CardWithRef[], boolean] => {
  const [linkedCards, setLinkedCards] = useState<CardWithRef[]>([]);
  const [loading, setLoading] = useState(true);

  const getRefs = async (tag) => {
    const docSnap = await getDoc(doc(firestore, "tags", "allTags"));
    if (!docSnap.exists()) {
      console.log("No such document!");
      return [];
    }

    const tagData = docSnap.data();

    if (!tagData[tag]) {
      return [];
    }

    return tagData[tag];
  };

  const getPathTtitles = async (
    pathInfo: CardPathInfo & { isTrial: boolean }
  ) => {
    const topicDocSnap = await getDoc(
      doc(firestore, "topics", pathInfo.topicId)
    );
    const topicDoc = topicDocSnap.data();
    const chapterDocSnap = await getDoc(
      doc(firestore, "topics", pathInfo.topicId, "chapters", pathInfo.chapterId)
    );
    const chapterDoc = chapterDocSnap.data();
    const deckDocSnap = await getDoc(
      doc(
        firestore,
        "topics",
        pathInfo.topicId,
        "chapters",
        pathInfo.chapterId,
        "decks",
        pathInfo.deckId
      )
    );

    const deckDoc = deckDocSnap.data();

    const isTrial = chapterDoc.isTrial || deckDoc.isTrial;

    return {
      topicTitle: topicDoc.title,
      chapterTitle: chapterDoc.title,
      deckTitle: deckDoc.title,
      isTrial,
      topicKey: topicDoc.topicKey,
    };
  };

  useEffect(() => {
    if (!tag) {
      return;
    }
    // Replace whitespace with underscore
    const tagId = tag.replace(/\s/g, "_");
    setLoading(true);
    getRefs(tagId)
      .then((refs) => {
        return Promise.all(refs.map((ref) => getDoc(ref)));
      })
      .then((cardsSnap) => {
        // Retun cards to next then
        return cardsSnap.map((snap) => {
          const cardData = snap.data();
          const path = snap.ref.path;
          const pathInfo = {
            topicId: path.split("/")[1],
            chapterId: path.split("/")[3],
            deckId: path.split("/")[5],
            cardId: path.split("/")[7],
          };
          return { ...cardData, pathInfo, ref: snap.ref };
        });
      })
      .then((cards) => {
        return Promise.all(
          cards.map((card) => {
            return getPathTtitles(card.pathInfo).then((pathTitles) => {
              return {
                ...card,
                pathInfo: { ...card.pathInfo, ...pathTitles },
                isTrial: pathTitles.isTrial,
                topicKey: pathTitles.topicKey,
              };
            });
          })
        );
      })
      .then((cards) => {
        setLinkedCards(cards);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [tag]);

  return [linkedCards, loading];
};
