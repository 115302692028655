/**
 * To find your Firebase config object:
 *
 * 1. Go to your [Project settings in the Firebase console](https://console.firebase.google.com/project/_/settings/general/)
 * 2. In the "Your apps" card, select the nickname of the app for which you need a config object.
 * 3. Select Config from the Firebase SDK snippet pane.
 * 4. Copy the config object snippet, then add it here.
 */

const firebaseConfig = {
  apiKey: "AIzaSyAfEjneuetBin4pBS37f0EO2Imq3hO_HF8",
  authDomain: "jurahilfe-app-5d4d7.firebaseapp.com",
  projectId: "jurahilfe-app-5d4d7",
  storageBucket: "jurahilfe-app-5d4d7.appspot.com",
  messagingSenderId: "956290861351",
  appId: "1:956290861351:web:c41691c2804551a54e4102",
};

const reCaptchaV3ProviderId = "";

export function getFirebaseConfig() {
  if (!firebaseConfig || !firebaseConfig.apiKey) {
    throw new Error(
      "No Firebase configuration object provided." +
        "\n" +
        "Add your web app's configuration object to firebase-config.ts"
    );
  } else {
    return firebaseConfig;
  }
}
export function getRecaptchaProviderConfig() {
  return reCaptchaV3ProviderId;
}
