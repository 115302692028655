import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

import Annotation from "@components/shared/Annotation";
import HTMLText from "./HTMLText";
import { SearchHit } from "./search";

/**
 * Breadcrumbs for the search results that show the topic, chapter and deck that the result belongs to in a compact format with a tooltip that shows the full path
 */
const MiniBreadcrumbs = ({
  hit,
  query,
  className,
  showCardTitle = false,
}: {
  hit: Omit<SearchHit, "isTrial" | "topicKey">;
  query?: string;
  className?: string;
  showCardTitle?: boolean;
}) => {
  const [topicTitle, setTopicTitle] = useState("");
  const [chapterTitle, setChapterTitle] = useState("");
  const [deckTitle, setDeckTitle] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (query) {
      setTopicTitle(hit._highlightResult.topicTitle?.value);
      setChapterTitle(hit._highlightResult.chapterTitle?.value);
      setDeckTitle(hit._highlightResult.deckTitle?.value);
      setTitle(hit._highlightResult.title?.value);
    } else {
      setTopicTitle(hit.topicTitle);
      setChapterTitle(hit.chapterTitle);
      setDeckTitle(hit.deckTitle);
      setTitle(hit.title);
    }
  }, [hit, query]);

  if (!title || !deckTitle || !chapterTitle || !topicTitle) return null;

  const CrumbChevron = ({ className }: { className?: string }) => (
    <ChevronRightIcon
      className={clsx("inline-block h-4 w-4 flex-none", className)}
    />
  );

  return (
    <Annotation
      content={
        <div
          className={clsx(
            "flex flex-wrap items-center text-sm text-slate-500 dark:text-slate-300"
          )}
        >
          <HTMLText htmlString={topicTitle} />
          <CrumbChevron />
          <HTMLText htmlString={chapterTitle} />
          <CrumbChevron />
          <HTMLText htmlString={deckTitle} />
          <CrumbChevron />
          <HTMLText htmlString={title} className="font-semibold" />
        </div>
      }
    >
      <div
        className={clsx(
          "flex items-center text-sm text-slate-500 dark:text-slate-400",
          className
        )}
      >
        <HTMLText htmlString={topicTitle} className="whitespace-nowrap" />
        <CrumbChevron />
        <HTMLText htmlString={chapterTitle} className="whitespace-nowrap" />
        <CrumbChevron />
        <HTMLText
          htmlString={deckTitle}
          className="truncate whitespace-nowrap"
        />
      </div>
      {showCardTitle && (
        <div className="mt-0.5">
          <CrumbChevron className="-mt-0.5" />
          <HTMLText className="font-semibold" htmlString={title} />
        </div>
      )}
    </Annotation>
  );
};

export default MiniBreadcrumbs;
