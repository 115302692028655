import React, { FC } from "react";
import clsx from "clsx";
import Spinner from "./Spinner";

export interface YoutubeEmbedProps {
  embedId: string;
  className?: string;
  embedSettings?: {
    autoplay?: boolean;
    cc_load_policy?: boolean;
    mute?: boolean;
    loop?: boolean;
  };
}

export const YoutubeEmbed: FC<YoutubeEmbedProps> = ({
  embedId,
  className,
  embedSettings = {},
}) => {
  const {
    autoplay = true,
    cc_load_policy = true,
    mute = true,
    loop = true,
  } = embedSettings;

  return (
    <div
      className={clsx(
        "overflow-hidden rounded-md border border-slate-300 bg-slate-300 dark:border-slate-700 dark:bg-slate-700",
        className
      )}
    >
      <div
        className={clsx("relative overflow-hidden")}
        // Creating the 16:9 ratio container by using the aspect ration (height ÷ width = aspect ratio)
        style={{ width: "100%", paddingBottom: "56.2%" }}
      >
        <div className="absolute inset-0 flex items-center justify-center">
          <Spinner className="h-10 w-10" />
        </div>
        <iframe
          className="absolute inset-0 h-full w-full"
          // Loop the video when finished
          src={`https://www.youtube.com/embed/${embedId}?autoplay=${
            autoplay ? 1 : 0
          }&modestbranding=${1}&rel=${1}&cc_load_policy=${
            cc_load_policy ? 1 : 0
          }&mute=${mute ? 1 : 0}&loop=${loop ? 1 : 0}&playlist=${embedId}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded Youtube"
        />
      </div>
    </div>
  );
};
