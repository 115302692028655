import FeedbackButton, {
  FeedbackButtonProps,
} from "@components/shared/FeedbackButton";
import clsx from "clsx";
import React, { FC } from "react";

export interface CardProps {
  children: React.ReactNode;
  isLast?: boolean;
  isHighlighted?: boolean;
  className?: string;
  hasOutline?: boolean;
  withContentFeedbackCollection?: FeedbackButtonProps | null;
}

const Card: FC<CardProps> = ({
  children,
  isLast,
  isHighlighted,
  className,
  hasOutline,
  withContentFeedbackCollection,
}) => {
  return (
    <div
      className={clsx(
        "group relative",
        isLast && "mb-[20vh]",
        isHighlighted
          ? "outline outline-2 outline-offset-2 outline-primary-lighter"
          : hasOutline && "border-orange-100 dark:border-orange-300",
        "flex max-w-3xl flex-col items-start justify-center gap-2 rounded-md border border-slate-100 bg-white px-4 shadow-sm dark:border-slate-700 dark:bg-slate-800 sm:px-4 lg:py-2 lg:px-6",
        "bg-gradient-to-b from-white to-white dark:from-slate-800 dark:to-slate-900/50",
        withContentFeedbackCollection && "pb-6",
        className
      )}
    >
      <div className="absolute right-0 bottom-0 pr-2 pb-1 md:opacity-0 md:group-hover:opacity-100">
        {withContentFeedbackCollection && (
          <FeedbackButton {...withContentFeedbackCollection} />
        )}
      </div>
      {children}
    </div>
  );
};

const CardHeader = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <h3 className={clsx("mb-1 font-semibold", className)}>{children}</h3>;
};

export default Object.assign(Card, {
  Header: CardHeader,
});
