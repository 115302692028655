import { resolveValue, Toaster as ReactHotToaster } from "react-hot-toast";

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import clsx from "clsx";

function Toaster() {
  return (
    <ReactHotToaster position={"top-right"}>
      {(t) => {
        return (
          <div className={`${t.visible ? "animate-enter" : "animate-leave"}`}>
            {(["success", "error", "blank", "custom"].includes(t.type) && (
              <div className="mx-auto flex w-80 max-w-sm overflow-hidden rounded-lg bg-white shadow-md sm:w-96">
                <div
                  className={clsx(
                    "flex w-[60px] items-center justify-center",
                    t.type === "success" && "bg-lime-500",
                    t.type === "blank" && "bg-gray-400 dark:bg-gray-700",
                    t.type === "error" && "bg-red-500"
                  )}
                >
                  {t.icon ? (
                    t.icon
                  ) : t.type === "success" ? (
                    <CheckCircleIcon className="h-6 w-6 text-white" />
                  ) : t.type === "blank" ? (
                    <ExclamationCircleIcon className="h-6 w-6 text-white" />
                  ) : t.type === "error" ? (
                    <ExclamationCircleIcon className="h-6 w-6 text-white" />
                  ) : null}
                </div>

                <div className="flex-1 px-4 py-3">
                  <div
                    className={clsx(
                      "mb-1 font-semibold leading-5",
                      t.type === "success" && "text-lime-600",
                      t.type === "blank" && "text-slate-600",
                      t.type === "error" && "text-red-500"
                    )}
                  >
                    {t.type === "success" && <span>Super!</span>}
                    {/* {t.type === "blank" && <span>Hinweis</span>} */}
                    {t.type === "error" && <span>Fehler</span>}
                  </div>
                  <div className="text-sm leading-5 text-gray-800">
                    {resolveValue(t.message, t)}
                  </div>
                </div>
              </div>
            )) ||
              resolveValue(t.message, t)}
          </div>
        );
      }}
    </ReactHotToaster>
  );
}

export const ToastHeader = ({ children }) => {
  return (
    <span className="mb-2 text-base font-semibold leading-5 text-slate-700">
      {children}
    </span>
  );
};

export const ToastBody = ({ children }) => {
  return <div className="text-sm text-gray-700">{children}</div>;
};

export default Toaster;
