import { useState, useEffect } from "react";
import { useWindowSize } from "react-use";

export const useMainHeight = () => {
  const { height } = useWindowSize();
  const [mainHeight, setMainHeight] = useState("0px");

  useEffect(() => {
    // 64px for the height + 1px for the border (more reliable than box-sizing:border-box)
    setMainHeight((height - 65).toString() + "px");
  }, [height]);

  return mainHeight;
};
