import clsx from "clsx";
import { FC } from "react";

export type BadgeType = "neutral" | "highlight" | "upgrade";
export type BadgeSize = "small" | "medium" | "large";

export interface BadgeProps {
  children: React.ReactNode;
  type?: BadgeType;
  size?: BadgeSize;
  className?: string;
}

const Badge: FC<BadgeProps> = ({
  children,
  type = "neutral",
  size = "medium",
  className,
  ...props
}) => {
  return (
    <span
      className={clsx(
        "whitespace-nowrap font-medium",
        "inline-flex select-none items-center justify-center rounded-md",
        size === "small" && "px-1.5 py-0.5 text-xs",
        size === "medium" && "px-2 py-1 text-xs",
        size === "large" && "px-3 py-1.5 text-sm",
        type === "neutral" &&
          "bg-slate-50/10 text-gray-600 ring-1 ring-inset ring-slate-600/20 dark:bg-slate-400/10 dark:text-gray-300 dark:ring-slate-400/20",
        type === "highlight" &&
          "bg-orange-50  text-orange-600 ring-1 ring-inset ring-orange-600/20 dark:bg-orange-400/10 dark:text-orange-500 dark:ring-orange-400/20",
        type === "upgrade" && [
          "bg-gradient-to-br from-orange-600/80 to-pink-600/80 shadow-orange-500/50 ring-1 ring-inset ring-orange-600/40 dark:from-orange-600/40 dark:to-pink-600/40",
          "transition",
          "text-white",
          "!font-semibold",
        ],
        className
      )}
      {...props}
    >
      {children}
    </span>
  );
};

export default Badge;
