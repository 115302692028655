import { usePlausible } from "next-plausible";
import { track } from "@utils/heapEvents";

export const useTrack = () => {
  const plausible = usePlausible();

  const logEvent = (eventName: string) => {
    plausible(eventName);
    track(eventName);
  };

  return logEvent;
};
