import { ExtendedFirebaseUser, ContentLimit } from "@types";

export const getIsProBlocked = (
  isProContent: boolean,
  user: ExtendedFirebaseUser,
  contentType?: ContentLimit
) => {
  const proUser = user?.subscription?.status === "pro";
  const hasContentLimit = contentType && user?.subscription?.contentLimit;

  // Temporarily special handling for "oeffr" content types (all users can access)
  if (contentType === "oeffr") {
    return false;
  }

  // Special case handling for "jaw" content type
  if (contentType === "jaw" || contentType === "guides") {
    return !proUser || hasContentLimit; // Only allow if the user is pro and has no content limit (= Pro Plus)
  }

  const isBlocked =
    // If the content / feature is pro, and the user is not pro
    (isProContent && !proUser) ||
    // If the user has a content limit, and the content type is not the same as the user's content limit
    (isProContent &&
      hasContentLimit &&
      contentType !== user?.subscription?.contentLimit);

  return isBlocked;
};
