declare global {
  interface Window {
    plausible: any;
  }
}

function gtag(...args: any) {
  window.dataLayer.push(args);
}

function collectAnalyticsEvent({
  eventName,
  props,
}: {
  eventName: string;
  props?: Record<string, any>;
}) {
  // only execute it on the client
  if (typeof window === "undefined") {
    return;
  }

  const provider = process.env.NEXT_PUBLIC_ANALYTICS_PROVIDER;

  if (provider === "google") {
    // Push the event to the dataLayer from which the
    // Google Analytics V4 script will read and process it
    window.dataLayer = window.dataLayer || [];

    gtag("event", eventName);
  } else if (provider === "plausible") {
    window.plausible?.(eventName, props || {});
  } else if (provider === "heap") {
    window.heap?.track(eventName, props || {});
  }
}

export default collectAnalyticsEvent;
