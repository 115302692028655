import { OnboardingStep } from "@types";

// We could also add ctaFunction and ctaText to the steps
export const getOnboardingSteps: () => OnboardingStep[] = () => {
  return [
    {
      id: "start-session",
      title: "Starte deine erste Lernsession",
      subtitle:
        "Leg los, um deine Noten zu verbessern! Klick einfach bei einem Rechtsgebiet auf 'Lernen starten' und wähle dann dein Thema aus.",
    },
    {
      id: "complete-path",
      title: "Schließe deinen ersten Lernpfad ab",
      subtitle:
        "Vervollständige einen kompletten Lernpfad, bestehend aus Stufe 1: 'Lernen', Stufe 2: 'Wiederholen' und Stufe 3: 'Falltraining & Abschlusstest'.",
    },
    {
      id: "follow-link",
      title: "Folge einem verlinkten Rechtsbegriff",
      subtitle:
        "All unsere Texte sind interaktiv. Klicke auf ein umrahmtes Wort und schau dir die Definition oder das Begleitwissen im Kontext an.",
    },
    {
      id: "search-function",
      title: "Finde ein Thema mit der Suchfunktion",
      subtitle:
        "Finde gezielt dein Rechtsproblem mit unserer Volltextsuche. Für einen schnellen Blick genügt die Vorschau, Details kannst du dir im Kontext ansehen. Öffnen lässt sich die Suchfunktion über die Navigationsleiste oben oder die Tastenkombination ⌘ + K (Apple) oder Ctrl + K (Windows).",
    },
  ];
};
