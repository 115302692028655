import clsx from "clsx";

const TrialSteps = ({ trialSteps }) => (
  <div className="flow-root">
    <ul role="list" className="-mb-5 sm:-mb-8">
      {trialSteps.map((step, stepIdx) => (
        <li key={step.id}>
          <div className="relative pb-5 sm:pb-8">
            {stepIdx !== trialSteps.length - 1 ? (
              <span
                aria-hidden="true"
                className={clsx(
                  "absolute left-0 top-4 h-full w-10 bg-gradient-to-b from-orange-500/20 to-orange-500/40"
                )}
              />
            ) : (
              <span
                aria-hidden="true"
                className={clsx(
                  "absolute left-0 top-4 h-full w-10 rounded-b-full bg-gradient-to-b from-orange-500/20 to-transparent"
                )}
              />
            )}
            <div className="relative flex space-x-4">
              <div>
                <span
                  className={clsx(
                    "flex h-10 w-10 items-center justify-center rounded-full bg-orange-500 shadow dark:bg-orange-600"
                  )}
                >
                  <step.icon
                    aria-hidden="true"
                    className="h-4 w-4 text-white"
                  />
                </span>
              </div>
              <div className="flex flex-col justify-start pt-0.5">
                <div>
                  <p className="text-sm font-bold text-orange-500">
                    {step.title}
                  </p>
                </div>
                <div className="text-left text-sm text-gray-500 dark:text-slate-200">
                  {step.description}
                </div>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  </div>
);

export default TrialSteps;
