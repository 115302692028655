import React, { FC } from "react";
import Link from "next/link";
import clsx from "clsx";
import { usePlausible } from "next-plausible";

export interface MenuLinkProps {
  children: React.ReactNode;
  href: string;
  isActive?: boolean;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  type?: "default" | "primary";
  newTab?: boolean;
  plausbileEvent?: "register" | "login";
}

const MenuLink: FC<MenuLinkProps> = ({
  children,
  href,
  isActive = false,
  Icon,
  type = "default",
  newTab = false,
  plausbileEvent,
}) => {
  const plausible = usePlausible();

  return (
    <Link
      href={href}
      target={newTab ? "_blank" : undefined}
      rel={newTab ? "noopener noreferrer" : undefined}
      className={clsx(
        type === "primary" && "border border-orange-500",
        isActive ? "btn-text-default-active" : "btn-text-default",
        "group flex items-center rounded-md px-3 py-2 text-sm font-medium dark:focus:ring-offset-gray-800"
      )}
      aria-current={isActive ? "page" : undefined}
      onClick={() => plausbileEvent && plausible(plausbileEvent)}
    >
      {Icon && (
        <Icon className={"mr-3 h-4 w-4 flex-shrink-0"} aria-hidden="true" />
      )}
      {children}
    </Link>
  );
};

export default MenuLink;
