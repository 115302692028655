import { doc, collection, getDocs, setDoc } from "firebase/firestore";
import { round } from "lodash";

import {
  BaseDeckFirestore,
  ChapterFirestore,
  ProgressSteps,
  UserChapter,
  UserDeck,
} from "@types";

import { firestore } from "./firebase-init";

// TODO: Adjust and add types

// Utility function to parse the step number from a string
const parseStep = (step: string) => parseInt(step, 10) as keyof ProgressSteps;

// Utility function to calculate weighted average
const calculateWeightedAverage = (
  values: number[],
  weights: number[]
): number => {
  const totalWeight = weights.reduce((acc, weight) => acc + weight, 0);
  if (totalWeight === 0) return 0;
  const weightedSum = values.reduce(
    (acc, value, index) => acc + value * weights[index],
    0
  );
  return round(weightedSum / totalWeight, 2);
};

// Utility function to calculate weighted average for steps
const getAveragedItemsProgress = (
  stepValues: Record<keyof ProgressSteps, [number, number, number][]>
) =>
  Object.keys(stepValues).reduce((acc, step) => {
    const stepNumber = parseStep(step);
    acc[stepNumber] = calculateWeightedAverage(
      stepValues[stepNumber].map((step) => step[0]),
      stepValues[stepNumber].map((step) => step[2])
    );
    return acc;
  }, {} as ProgressSteps);

// Utility function to calculate aggregated step times
const getAggregatedStepTimes = (
  stepValues: Record<keyof ProgressSteps, [number, number, number][]>
) =>
  Object.keys(stepValues).reduce((acc, step) => {
    const stepNumber = parseStep(step);
    acc[stepNumber] = round(
      stepValues[step].reduce((acc, step) => acc + step[1], 0),
      2
    );
    return acc;
  }, {} as ProgressSteps);

// Utility function to get the combined items
const getCombinedItems = (baseItems: any[], userItems: any[]) => {
  const defaultProgress: ProgressSteps = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
  };
  const userItemMap = userItems.reduce((acc, userItem) => {
    acc[userItem.id] = userItem;
    return acc;
  }, {} as Record<string, UserDeck | UserChapter>);

  return baseItems.map((baseItem) => {
    const userItem = userItemMap[baseItem.id];

    const progress = {
      ...defaultProgress,
      ...userItem?.progress,
    };

    const stepTimes = {
      ...defaultProgress,
      ...userItem?.stepTimes,
    };

    return { ...baseItem, progress, stepTimes };
  });
};

/**
 * Aggregates and updates the progress for a given user, topic, and optionally chapter.
 *
 * This function calculates and updates the aggregated progress of decks within a chapter
 * or chapters within a topic for a user. It fetches the total number of items (decks or
 * chapters) from the respective Firestore collections, aggregates the user's progress
 * data and updates the progress in the Firestore document.
 *
 * @param {string} userId - The ID of the user whose progress is being updated.
 * @param {string} topicId - The ID of the topic.
 * @param {string} [chapterId] - (Optional) The ID of the chapter. If provided, the function
 *                               aggregates progress for decks within the chapter. If not
 *                               provided, it aggregates progress for chapters within the topic.
 */
const aggregateProgress = async (
  userId: string,
  topicId: string,
  chapterId?: string
) => {
  const basePath = `topics/${topicId}`;
  const userBasePath = `userProgress/${userId}/forTopics/${topicId}`;

  const refPath = chapterId
    ? `${userBasePath}/forChapters/${chapterId}`
    : userBasePath;
  const subCollectionName = chapterId ? "forDecks" : "forChapters";
  const baseCollectionPath = chapterId
    ? `${basePath}/chapters/${chapterId}/decks`
    : `${basePath}/chapters`;

  const ref = doc(firestore, refPath);
  const subCollectionRef = collection(firestore, refPath, subCollectionName);

  // Fetch the base items (decks or chapters)
  const baseItemsSnap = await getDocs(
    collection(firestore, baseCollectionPath)
  );
  const baseItems = baseItemsSnap.docs.map((doc) => ({
    ...(doc.data() as BaseDeckFirestore | ChapterFirestore),
    id: doc.id,
  }));

  // Fetch the latest progress from all user-interacted items
  const userItemSnaps = await getDocs(subCollectionRef);
  const userItems = userItemSnaps.docs.map((doc) => ({
    ...(doc.data() as UserDeck | UserChapter),
    id: doc.id,
  }));

  // Combine base items with user progress
  const combinedItems = getCombinedItems(baseItems, userItems);

  // Create an object with the 4 steps as keys containing arrays for each step
  const stepValues: Record<keyof ProgressSteps, [number, number, number][]> = {
    1: [],
    2: [],
    3: [],
    4: [],
  };

  // Loop over the combined items and collect the values
  combinedItems.forEach((item) => {
    Object.keys(stepValues).forEach((step) => {
      const stepNumber = parseStep(step);
      const counts = item.counts || { cards: 0, questions: 0 };
      const requiredCount = stepNumber === 3 ? counts.questions : counts.cards;
      stepValues[stepNumber].push([
        item.progress[stepNumber],
        item.stepTimes[stepNumber],
        requiredCount,
      ]);
    });
  });

  const progress = {
    progress: getAveragedItemsProgress(stepValues),
    stepTimes: getAggregatedStepTimes(stepValues),
  };

  await setDoc(ref, progress, { merge: true });
};

// Wrapper functions for specific use cases
export const aggregateChapterProgress = async (
  userId: string,
  topicId: string,
  chapterId: string
) => {
  await aggregateProgress(userId, topicId, chapterId);
};

export const aggregateTopicProgress = async (
  userId: string,
  topicId: string
) => {
  await aggregateProgress(userId, topicId);
};

/**
 * Aggregate and update the progress for both a specific chapter and the overall topic for a given user.
 *
 * This function calls the `aggregateProgress` function twice: first to aggregate and update the progress
 * for a specific chapter (aggregating the progress of decks within that chapter), and
 * then to update the overall progress for the topic (aggregating the progress of chapters
 * within that topic). This ensures that both the chapter-level and topic-level progress
 * are accurately calculated and updated in the Firestore documents.
 *
 * @param {string} userId - The ID of the user whose progress is being updated.
 * @param {string} topicId - The ID of the topic.
 * @param {string} chapterId - The ID of the chapter. The function will first update the
 *                             progress for this chapter and then update the progress for
 *                             the entire topic.
 */
export const aggregateChapterAndTopicProgress = async (
  userId: string,
  topicId: string,
  chapterId: string
) => {
  await aggregateChapterProgress(userId, topicId, chapterId);
  await aggregateTopicProgress(userId, topicId);
};
