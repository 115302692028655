import { PricingTier } from "@components/app/PricingPage/pricingTiers";
import { StripeProduct } from "@types";
import { getPriceDisplayInfo } from "@utils/getPriceDisplayInfo";

/**
 * Extends the tier data with stripe product and user data.
 * @param tier - The tier to extend
 * @param stripeProduct - The stripe product to extend the tier with
 * @param user - The user to extend the tier with
 * @returns The tier, extended with extra attributes based on the user and stripe product.
 * Note: The stripePriceId for the intervals is set based on the NODE_ENV.
 */
export const extendTierData = (
  tier: PricingTier,
  stripeProduct: StripeProduct | undefined,
  user: any
): PricingTier => {
  const stripeId =
    process.env.NODE_ENV === "development" ? tier.stripeIdDev : tier.stripeId;

  const extended = {
    ...tier,
    active:
      (stripeId && stripeId === user?.subscription?.productId) ||
      (user && user.subscription?.status === "free" && tier.isFree),
  };

  // If the tier is free, we set the price to 0 and don't fetch stripe prices
  if (extended.isFree) {
    extended.price = {
      monthly: {
        ...extended.price?.monthly,
        displayInfo: {
          pricePerInterval: "0 €",
          priceMonth: "0 €",
          interval: "Monat",
        } as any,
      },
      annually: {
        ...extended.price?.annually,
        displayInfo: {
          pricePerInterval: "0 €",
          priceMonth: "0 €",
          interval: "Jahr",
        } as any,
      },
    };
    return extended;
  }

  if (stripeProduct && extended.price) {
    // Add the stripe product to the tier
    extended.stripeProduct = stripeProduct;

    // Loop over tier prices and add the stripe price to the tier
    Object.entries(extended.price).forEach(([key, value]) => {
      const stripePrice = stripeProduct.prices.find((price) => {
        const tierPriceId =
          process.env.NODE_ENV === "development"
            ? value.stripePriceIdDev
            : value.stripePriceId;
        return price.id === tierPriceId;
      });

      if (!stripePrice) {
        console.error(
          `Could not find stripe price with id ${value.stripePriceId} in product ${stripeProduct.id}`
        );
        extended.comingSoon = true;
        // End this iteration of the loop
        return;
      }

      const displayInfo = getPriceDisplayInfo(
        stripePrice?.interval,
        stripePrice?.unit_amount
      );

      // Add the stripe price to the tier
      extended.price[key] = {
        ...value,
        stripePriceId:
          process.env.NODE_ENV === "development"
            ? extended.price[key].stripePriceIdDev
            : extended.price[key].stripePriceId,
        displayInfo,
        stripePrice,
      };
    });
  } else {
    extended.comingSoon = true;
  }

  return extended;
};
