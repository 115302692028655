import {
  collectionGroup,
  getDocs,
  query,
  where,
  doc,
  setDoc,
} from "firebase/firestore";
import { firestore } from "./firebase-init";
import { aggregateChapterAndTopicProgress } from "./aggregateChapterAndTopicProgress";

export const aggregateInitialProgress = async (
  userId: string
): Promise<void> => {
  try {
    if (!userId) {
      console.error("No userId provided");
      throw new Error("No userId provided");
    }

    console.log("🧿 Aggregate initial progress for user", userId);

    // Get all decks of the user
    const deckSnaps = await getDocs(
      query(
        collectionGroup(firestore, "forDecks"),
        where("userId", "==", userId)
      )
    );

    const chaptersMap = new Map<
      string,
      { topicId: string; chapterId: string }
    >();

    deckSnaps.forEach((deckSnap) => {
      const topicId = deckSnap.ref.parent.parent.parent.parent.id;
      const chapterId = deckSnap.ref.parent.parent.id;
      const key = `${topicId}_${chapterId}`;

      if (!chaptersMap.has(key)) {
        chaptersMap.set(key, { topicId, chapterId });
      }
    });

    const uniqueChapters = Array.from(chaptersMap.values());

    console.log("🧿 Found unique chapters", uniqueChapters);

    // Call aggregateChapterAndTopicProgress for each chapter
    const progressPromises = uniqueChapters.map((chapter) => {
      return aggregateChapterAndTopicProgress(
        userId,
        chapter.topicId,
        chapter.chapterId
      );
    });

    await Promise.all(progressPromises);

    // Set the user settings
    await setDoc(
      doc(firestore, "customers", userId, "appSettings", "migration"),
      {
        migratedProgress: true,
      },
      { merge: true }
    );

    return Promise.resolve();
  } catch (error) {
    console.error("Error aggregating initial progress", error);
    throw new Error("Error aggregating initial progress");
  }
};
