import { FC, Fragment, useEffect, useRef } from "react";
import clsx from "clsx";
import { useMedia } from "react-use";
import { Dialog, Transition } from "@headlessui/react";
import { LinkIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/solid";

import Button from "./Button";
import Logo from "./Logo";
import useStore from "@hooks/useStore";

export type ModalType =
  | "info"
  | "success"
  | "error"
  | "warning"
  | "link"
  | "upgrade";

export interface ModalProps {
  title: React.ReactNode;
  children: React.ReactNode;
  open: boolean;
  setClose: () => void;
  type?: ModalType;
  onConfirm?: () => void;
  closeOnConfirm?: boolean;
  onCancel?: () => void;
  confirmButtonText?: React.ReactNode;
  cancelButtonText?: string;
  size?: "small" | "medium" | "large" | "xlarge";
  position?: "contentAreaCentered" | "fullscreen" | "default";
  showCloseButton?: boolean;
  buttonPosition?: "center" | "right";
  closeOnOutsideClick?: boolean;
  fullscreen?: boolean;
  loading?: boolean;
  multiStepConfig?: {
    enabled: boolean;
    currentStep: number;
    totalSteps: number;
    onNextStep: () => void;
    onPreviousStep: () => void;
    nextButtonText?: string;
    previousButtonText?: string;
  };
  customFooter?: React.ReactNode;
}

interface StepProps {
  currentStep: number;
  totalSteps: number;
  onNextStep: () => void;
  onPreviousStep: () => void;
  nextButtonText?: string;
  previousButtonText?: string;
  onComplete: () => void;
  confirmButtonText?: string;
  type?: ModalType;
  loading?: boolean;
}

const StepNavigation: FC<StepProps> = ({
  currentStep,
  totalSteps,
  onNextStep,
  onPreviousStep,
  nextButtonText = "Weiter",
  previousButtonText = "Zurück",
  onComplete,
  confirmButtonText,
  type,
  loading,
}) => (
  <div className="mt-4 flex w-full items-center justify-between gap-4">
    {currentStep > 1 && (
      <Button
        size="small"
        width="auto"
        type="tertiary"
        onClick={onPreviousStep}
        loading={loading}
      >
        <ArrowLeftIcon className="mr-1 inline-block h-3 w-3" />
        {previousButtonText}
      </Button>
    )}
    {currentStep < totalSteps ? (
      <Button
        size="small"
        width="auto"
        type="primary"
        className="ml-auto"
        onClick={onNextStep}
        loading={loading}
      >
        {nextButtonText}
        <ArrowRightIcon className="ml-1 inline-block h-3 w-3" />
      </Button>
    ) : confirmButtonText ? (
      <Button
        size="small"
        type={type === "upgrade" ? "upgrade" : "primary"}
        className="ml-auto"
        width="auto"
        onClick={onComplete}
        loading={loading}
      >
        {confirmButtonText}
      </Button>
    ) : null}
  </div>
);

const Modal: FC<ModalProps> = ({
  title,
  type = "info",
  children,
  open,
  setClose,
  onConfirm,
  closeOnConfirm = true,
  onCancel,
  confirmButtonText = "Ok",
  cancelButtonText = "Abbrechen",
  size = "small",
  showCloseButton = true,
  position = "default",
  buttonPosition = "right",
  closeOnOutsideClick = true,
  multiStepConfig,
  loading,
  customFooter,
}) => {
  const isLg = useMedia("(min-width: 1024px)", true);
  const sidebarCollapsed = useStore((state) => state.sidebarCollapsed);

  // Get ref of scrollable content area
  const contentAreaRef = useRef<HTMLDivElement>(null);

  // Reset scroll position on open
  useEffect(() => {
    if (open) {
      contentAreaRef.current?.scrollTo(0, 0);
    }
  }, [open]);

  const handleCancel = () => {
    onCancel && onCancel();
    setClose();
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    if (closeOnConfirm) {
      setClose();
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={clsx(
          "relative",
          position === "contentAreaCentered"
            ? "z-50 sm:z-10"
            : position === "fullscreen"
            ? "z-50"
            : "z-50 sm:z-20"
        )}
        onClose={
          closeOnOutsideClick
            ? setClose
            : () => {
                console.log("Clicked outside, not closing modal");
              }
        }
        static
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-500 bg-opacity-75 backdrop-blur-sm transition-opacity dark:bg-slate-700 dark:bg-opacity-75" />
        </Transition.Child>

        <div
          className={clsx(
            "fixed inset-0 z-10 overflow-y-auto",
            position === "contentAreaCentered"
              ? [
                  "z-10",
                  isLg && !sidebarCollapsed && "pl-72 lg:pl-96",
                  "md:pt-24",
                ]
              : "z-20"
          )}
        >
          <div
            className={clsx(
              "flex min-h-full",
              "items-end justify-center py-4 px-2 text-center sm:items-center sm:p-0"
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  size === "small"
                    ? "py-4 sm:max-w-lg sm:pt-6 sm:pb-5"
                    : size === "medium"
                    ? "py-4 sm:max-w-2xl sm:pt-6 sm:pb-5"
                    : size === "large"
                    ? "py-4 sm:max-w-3xl sm:pt-6 sm:pb-5"
                    : "pt-7 pb-8 sm:max-w-5xl",
                  size === "small" ? "px-3 sm:px-4" : "px-3 sm:px-6",
                  "relative w-full transform rounded-lg bg-white text-left shadow-xl transition-all dark:bg-gray-800 sm:mx-2 sm:my-8 sm:w-full "
                )}
              >
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  {showCloseButton && (
                    <button
                      type="button"
                      className="btn-text-default rounded-md dark:focus:ring-offset-gray-800"
                      onClick={setClose}
                    >
                      <span className="sr-only">Schließen</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  )}
                </div>
                <div className="sm:flex sm:flex-col">
                  <div className="mb-4 flex flex-row items-center justify-between gap-2 pb-2 text-left sm:mt-0 sm:mb-0 sm:pb-4">
                    <div className="flex items-center gap-2">
                      <div
                        className={clsx(
                          type === "error"
                            ? "bg-red-100 text-red-600 dark:bg-red-900 dark:text-red-400"
                            : type === "success" || type === "link"
                            ? "bg-lime-100 text-lime-500 dark:bg-lime-700 dark:text-lime-300"
                            : type === "warning"
                            ? "bg-yellow-100 text-yellow-600 dark:bg-yellow-900 dark:text-yellow-400"
                            : type === "info"
                            ? "bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-300"
                            : type === "upgrade"
                            ? "bg-transparent sm:mr-2"
                            : "bg-slate-100 text-slate-500 dark:bg-slate-700 dark:text-slate-400",
                          "flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full sm:mx-0"
                        )}
                      >
                        {type === "error" ? (
                          <ExclamationCircleIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : type === "success" ? (
                          <CheckCircleIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : type === "warning" ? (
                          <ExclamationCircleIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : type === "upgrade" ? (
                          <Logo />
                        ) : type === "link" ? (
                          <LinkIcon className="h-6 w-6" aria-hidden="true" />
                        ) : (
                          <InformationCircleIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </div>
                      <Dialog.Title
                        as="h3"
                        className={clsx(
                          size === "xlarge" ? "text-xl" : "text-lg",
                          "flex items-center font-medium leading-6 text-slate-900 dark:text-slate-200 sm:h-10 sm:leading-6"
                        )}
                      >
                        {title}
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="">
                    <div
                      ref={contentAreaRef}
                      className={clsx(
                        size === "xlarge"
                          ? "max-h-[60vh]"
                          : position === "fullscreen"
                          ? "max-h-[80vh]"
                          : "max-h-[50vh]",
                        "overflow-y-auto text-sm text-slate-500 dark:text-slate-100 sm:px-2"
                      )}
                    >
                      {children}
                    </div>
                  </div>
                </div>
                <div className="relative mt-5 sm:mt-4">
                  {customFooter ? (
                    customFooter
                  ) : multiStepConfig?.enabled ? (
                    <div className="flex flex-grow flex-col items-center gap-2">
                      <div className="absolute bottom-3 left-1/2 -translate-x-1/2">
                        <StepIndicator
                          currentStep={multiStepConfig.currentStep}
                          totalSteps={multiStepConfig.totalSteps}
                        />
                      </div>

                      <StepNavigation
                        currentStep={multiStepConfig.currentStep}
                        totalSteps={multiStepConfig.totalSteps}
                        onNextStep={multiStepConfig.onNextStep}
                        onPreviousStep={multiStepConfig.onPreviousStep}
                        onComplete={() => {
                          onConfirm && onConfirm();
                          closeOnConfirm && setClose();
                        }}
                        confirmButtonText={confirmButtonText as string}
                        type={type}
                        nextButtonText={multiStepConfig.nextButtonText}
                        previousButtonText={multiStepConfig.previousButtonText}
                        loading={loading}
                      />
                    </div>
                  ) : (
                    <div
                      className={clsx(
                        buttonPosition === "right" && "ml-auto",
                        buttonPosition === "center" && "mx-auto",
                        "flex flex-col items-center gap-2 sm:max-w-min sm:flex-row-reverse"
                      )}
                    >
                      {confirmButtonText && (
                        <Button
                          loading={loading}
                          size="small"
                          type={
                            type === "warning"
                              ? "warning"
                              : type === "error"
                              ? "danger"
                              : type === "success" || type === "link"
                              ? "success"
                              : type === "upgrade"
                              ? "upgrade"
                              : "primary"
                          }
                          onClick={handleConfirm}
                          className="whitespace-nowrap"
                        >
                          {confirmButtonText}
                        </Button>
                      )}
                      {cancelButtonText && (
                        <Button
                          loading={loading}
                          size="small"
                          type="tertiary"
                          onClick={handleCancel}
                          className="whitespace-nowrap"
                        >
                          {cancelButtonText}
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;

const StepIndicator = ({ currentStep, totalSteps }) => (
  <div className="flex justify-center space-x-2">
    {Array.from({ length: totalSteps }).map((_, index) => (
      <div
        key={index}
        className={clsx(
          "h-2 w-2 rounded-full",
          index + 1 === currentStep
            ? "bg-orange-500 ring-2 ring-orange-500/80 ring-offset-1 ring-offset-white dark:ring-offset-gray-800"
            : index + 1 < currentStep
            ? "bg-orange-500"
            : "bg-gray-400/20"
        )}
      />
    ))}
  </div>
);
