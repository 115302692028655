import { NextRouter } from "next/router";
import { httpsCallable } from "firebase/functions";
import { functions } from "@utils/firebase/firebase-init";

import { ExtendedFirebaseUser } from "@types";

export const startCustomerPortal = async (
  user: ExtendedFirebaseUser,
  setLoading: (boolean) => void,
  router: NextRouter
) => {
  if (!user) return;

  try {
    setLoading(true);

    const customerPortalLinkRef = httpsCallable(
      functions,
      process.env.NEXT_PUBLIC_STRIPE_FUNCTION_PORTAL_LINK
    );
    const { data } = await customerPortalLinkRef({
      returnUrl: window.location.origin + router.pathname,
      locale: "de", // Optional, defaults to "auto"
    });
    router.push((data as any).url);
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};
