import Button from "@components/shared/Button";
import { CheckIcon } from "@heroicons/react/24/outline";

const PaymentSuccessDisplay = ({ handleCustomerPortal, loading }) => {
  return (
    <div className="w-64 space-y-6 px-4 py-5 sm:p-6">
      <div className="flex flex-col justify-center">
        <div className="flex items-center justify-center">
          <div className="flex items-center justify-center  rounded-full bg-green-500 p-3 text-white ">
            <CheckIcon className="h-6 w-6" />
          </div>
        </div>
        <div className="mt-3 mb-5 text-center text-xl font-extrabold text-slate-700 dark:text-slate-200">
          Upgrade erfolgreich!
        </div>
        <Button
          onClick={handleCustomerPortal}
          loading={loading}
          type="tertiary"
          className="mt-4 flex w-full items-center justify-center rounded-md border border-transparent bg-slate-800 px-3 py-1 text-sm font-medium text-white hover:bg-slate-900 focus:outline-none"
        >
          Verwalte hier dein Abo!
        </Button>
      </div>
    </div>
  );
};

export default PaymentSuccessDisplay;
