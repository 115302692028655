import { useEffect, useState } from "react";
import useStore from "@hooks/useStore";
import { aggregateInitialProgress } from "@utils/firebase/aggregateInitialProgress";

/**
 * Perform migrations on user data
 * @param isInitialized Indicates if the store is initialized, otherwise the migrations will not run
 * @returns Whether the migrations are currently running
 */
export const useMigrations = (isInitialized: boolean) => {
  const user = useStore((state) => state.user);
  const [migrating, setMigrating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!isInitialized) return;

    const runMigrations = async () => {
      if (!user || !user.uid || migrating || error) return;

      const { migration } = user.settings || {};

      if (!migration || !migration.migratedProgress) {
        setMigrating(true);
        try {
          await aggregateInitialProgress(user.uid);
        } catch (error) {
          setError("Error migrating user data");
          console.error("Migration error", error);
        } finally {
          setMigrating(false);
        }
      } else {
        console.log("Already aggregated initial progress");
      }

      // Add more migrations here
    };

    runMigrations();
  }, [isInitialized, user, migrating, error]);

  return migrating;
};
