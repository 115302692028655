import clsx from "clsx";
import { useEffect, useRef } from "react";
import { useSanitized } from "@hooks/useSanizied";
import useStore from "@hooks/useStore";
import ExportedImage from "next-image-export-optimizer";

const ContentDisplay = ({
  htmlString,
  className,
  hideLinkTags,
  demoMode,
}: {
  htmlString: string;
  className?: string;
  hideLinkTags?: string[];
  demoMode?: boolean;
}) => {
  const sanitizedContent = useSanitized(htmlString);

  const { openLinkPeek, openModal } = useStore((state) => ({
    openLinkPeek: state.openLinkPeek,
    openModal: state.openModal,
  }));

  const contentRef = useRef(null);

  const handleLinkClick = (tag) => {
    if (demoMode) {
      openModal({
        title: "Interaktive Links",
        content: (
          <div>
            <div className="mb-5 text-slate-700 dark:text-slate-300">
              Dieser Link würde dich zu einem anderen Lerninhalt führen. In der
              Jurahilfe.de Web App kannst du diese Links anklicken und direkt zu
              dem entsprechenden Lerninhalt navigieren.
            </div>
            <ExportedImage
              className="rounded-md"
              alt="Interkative Links"
              src="/linkDemo.gif"
              width={500}
              height={300}
            />
          </div>
        ),
        type: "success",
      });
    } else {
      openLinkPeek(tag);
    }
  };

  useEffect(() => {
    const content = contentRef.current;

    if (!content) return;

    const links = content.querySelectorAll(
      ".prose-answer-text span.matched-tag"
    );

    const onClick = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const tag = event.target.getAttribute("data-matched-tag");

      handleLinkClick(tag);
    };

    links.forEach((link) => {
      link.addEventListener("click", onClick);

      const linkData = link.getAttribute("data-matched-tag");
      // Check if link should be hidden, remove the matched-tag class
      if (
        hideLinkTags?.map((link) => link.replace(/ /g, "_")).includes(linkData)
      ) {
        link.classList.remove("matched-tag");
      }

      // Remove underline
      // Move up the dom tree for max 3 leves and check if any parent is <u> tag
      // let parent = link.parentElement;
      // let i = 0;
      // while (i < 3) {
      //   if (parent.tagName === "U") {
      //     parent.style.textDecoration = "none";
      //     break;
      //   }
      //   parent = parent.parentElement;
      //   i++;
      // }
    });

    return () => {
      links.forEach((link) => {
        link.removeEventListener("click", onClick);
      });
    };
  }, [sanitizedContent]);

  return (
    <div
      ref={contentRef}
      className={clsx("prose-answer-text", className)}
      dangerouslySetInnerHTML={{ __html: sanitizedContent.__html }}
    />
  );
};

export default ContentDisplay;
