import useStore from "@hooks/useStore";
import Modal from "./Modal";

const ConnectedModal = () => {
  const { modal, closeModal } = useStore((state) => ({
    modal: state.modal,
    closeModal: state.closeModal,
  }));

  return (
    <Modal
      title={modal.title}
      open={modal.isOpen}
      type={modal.type}
      setClose={closeModal}
    >
      {modal.content}
    </Modal>
  );
};

export default ConnectedModal;
