import CardComponent from "@components/shared/Card";
import ContentDisplay from "@components/shared/ContentDisplay";

const ReaderCard = ({ card }) => {
  return (
    <div key={card.id} className="mx-auto flex justify-center">
      <CardComponent
        className="my-1 w-full "
        // hasOutline
        withContentFeedbackCollection={{
          itemType: "card",
          itemId: card.id,
          cardTitle: card.title,
          question: card.question,
        }}
      >
        <ContentDisplay
          htmlString={card.answer_linked || card.answer}
          hideLinkTags={card.tags}
        />
      </CardComponent>
    </div>
  );
};

export default ReaderCard;
