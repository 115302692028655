import {
  AcademicCapIcon,
  ArrowPathIcon,
  BoltIcon,
  BookOpenIcon,
} from "@heroicons/react/24/solid";
import { SVGProps } from "react";

export interface StepConfigItem {
  title: string;
  explanation: string;
  icon: (
    props: SVGProps<SVGSVGElement> & {
      title?: string;
      titleId?: string;
    }
  ) => JSX.Element;
  progress?: number;
  introduction?: {
    goal: string;
    steps: string[];
    tips?: string[];
    abstract?: string;
    symbols?: { symbol: React.ReactNode; explanation: string }[];
    videoEmbedId?: string;
  };
}

export interface StepConfig {
  [key: number]: StepConfigItem;
}

const symbols = [
  {
    symbol: "○",
    explanation: "Meinung",
  },
  {
    symbol: "(+)",
    explanation: "Pro Argument; Meinung vorzugswürdig, weil…",
  },
  {
    symbol: "(-)",
    explanation: "Gegenargument; Meinung abzulehnen, weil…",
  },
  {
    symbol: <b className=" whitespace-nowrap">I. 1. a)</b>,
    explanation:
      "Kumulative oder chronologische Aufzählung / Voraussetzungen / Prüfungsschema",
  },
  {
    symbol: "•",
    explanation: "Alternative Aufzählung",
  },
  {
    symbol: "⇨",
    explanation: "Folge / Rechtsfolge",
  },
  {
    symbol: "≠",
    explanation: "Abgrenzung / Ausnahme",
  },
  {
    symbol: "✓",
    explanation: "Klausurtipps und Formulierungsvorschläge",
  },
  {
    symbol: <u>Text</u>,
    explanation: "Thema des Absatzes",
  },
  {
    symbol: <b>Text</b>,
    explanation: "Wichtige Aussage oder Norm ",
  },
];

export const stepConfig: StepConfig = {
  1: {
    title: "Verstehen",
    explanation: "Lerne die Karte zu verstehen",
    progress: 0.3,
    icon: BookOpenIcon,
    introduction: {
      goal: "Du hast einen vollständigen Überblick über das Rechtsgebiet gewonnen, die Basics verstanden und die Probleme erkannt und durchdrungen.",
      steps: [
        "Lies dir den Inhalt jedes Lernelements durch.",
        "Schlage fettgedruckte Normen immer nach. Lerne dicht am Gesetz.",
        "Wenn du alles verstanden hast, klicke auf weiter, um das nächste Lernelement aufzurufen.",
        "Wenn du das Modul vollständig durchgearbeitet hast, wird die nächste Stufe freigeschaltet.",
      ],
      tips: [
        "Wenn du am PC arbeitest, kannst du mithilfe der Leertaste oder der Pfeiltaste nach rechts (→) schnell durch das Modul navigieren.",
        "Wenn dir etwas unklar ist oder du eine Anpassung vorschlagen möchtest, schreibe uns eine kurze Nachricht über den Feedback-Button. Wir lesen jede Nachricht, beantworten deine Fragen und berücksichtigen dein Feedback.",
        "Arbeite dicht am Gesetz, es ist das einzige Hilfsmittel, das dir in der Klausur zur Verfügung steht.",
        "Da wir alles relevante Wissen für dich sehr kompakt und pointiert aufbereitet und strukturiert haben, eignet es sich perfekt als Vorlage, um dein Gesetz zu markieren und kommentieren (sofern erlaubt). Studiere die Vorgaben deines Landesjustizprüfungsamts und schöpfe die Möglichkeiten aus.",
      ],
      abstract:
        "Lies die Inhalte und durchdringe sie gedanklich. Hier findest du kompakte und prägnante Lernelemente statt langer Texte.",
      symbols: symbols,
      videoEmbedId: "y-YT2rycvss",
    },
  },

  2: {
    title: "Wiederholen",
    explanation: "Karteikarten wiederholen",
    progress: 0.1,
    icon: ArrowPathIcon,
    introduction: {
      goal: "Du hast das Wissen dieses Rechtsgebiet nicht nur verstanden, sondern verinnerlicht und dir so eingeprägt, dass du es in der Fallbearbeitung auswendig parat hast.",
      steps: [
        "Lies die Karteikarte durch und überlege dir im Kopf die Antwort. Geh dabei alles zugehörige Wissen durch, das du zuvor gelesen hast.",
        "Dreh die Karteikarte um und überprüfe deine Antwort. War sie vollständig?",
        "Wenn du die Karteikarte vollständig beantworten und das Begleitwissen wiedergeben konntest, klick auf “gewusst”. Ansonsten klick auf “nicht gewusst” (dann wird dir die Karteikarte später erneut vorgelegt).",
        "Wenn du alle Karteikarten gewusst hast, wird die nächste Stufe freigeschaltet.",
      ],
      tips: [
        "Wenn du am PC arbeitest, kannst du mithilfe der Leertaste die Kartekarte drehen und mit den Pfeiltasten die Karte als “gewusst” (→) oder “nicht gewusst” (←) markieren.",
        "Wenn dir etwas unklar ist oder du eine Anpassung vorschlagen möchtest, schreibe uns eine kurze Nachricht über den Feedback-Button. Wir lesen jede Nachricht, beantworten deine Fragen und berücksichtigen dein Feedback.",
      ],

      abstract:
        "Wiederhole die Inhalte auf Karteikarten mit Fragen und Antworten, um dein Verständnis zu überprüfen.",
      symbols: symbols,
      videoEmbedId: "KPTPQ0mSgNM",
    },
  },
  3: {
    title: "Falltraining & Abschlusstest",
    explanation: "Lerne die Karte zu üben",
    progress: 0.3,
    icon: AcademicCapIcon,
    introduction: {
      goal: "Du konntest das erarbeitete Wissen am Fall testen und so eine neue Perspektive darauf und ein Gespür für Unterschiede und Feinheiten bekommen. Mit der Abschlussnote bekommst du eine Vorstellung davon, wie gut du das Rechtsgebiet durchdrungen hast.",
      steps: [
        "Lies dir den Sachverhalt, Frage und Antworten durch. Achte dabei auch auf die Details und nimm dir Zeit für die Beantwortung der Fragen.",
        "Markiere alle richtigen Fragen mit einem Häkchen. Mehrfachantworten sind möglich, es können auch alle oder keine Antworten zutreffend sein.",
        "Klick auf “Antworten überprüfen” und sieh dir an, ob du die Häkchen richtig gesetzt hast.",
        "Wenn du alle Aufgaben durchgearbeitet hast, erhältst du eine Abschlussnote.",
      ],
      tips: [
        "Wenn du am PC arbeitest, kannst du mithilfe der Leertaste die Antworten überprüfen und mit der Pfeiltaste nach rechts (→) zur nächsten Aufgabe springen.",
        "Wenn dir etwas unklar ist oder du eine Anpassung vorschlagen möchtest, schreibe uns eine kurze Nachricht über den Feedback-Button. Wir lesen jede Nachricht, beantworten deine Fragen und berücksichtigen dein Feedback.",
      ],
      abstract:
        "In benoteten Multiple-Choice-Aufgaben werden Grundwissen und kleine Fälle abgefragt, um eine klausurnahe Perspektive auf das theoretische  Wissen zu gewinnen und zu verstehen, wie gut du das Rechtsgebiet bereits beherrschst.",
      videoEmbedId: "pJaIbjigYDA",
    },
  },
  4: {
    title: "Vertiefen",
    explanation: "Lerne die Karte zu testen",
    icon: BoltIcon,
    introduction: {
      goal: "Das erarbeitete Wissen wird im Langzeitgedächtnis gespeichert, offene Fragen und Verständnislücken werden behoben und dein Können im Umgang mit Klausuren verbessert.",
      steps: [
        "Du konntest dir bisher Wissen erarbeiten, wiederholen, üben und testen. Die Stufen kannst du jederzeit erneut starten, um das Wissen im Langzeitgedächtnis zu behalten.",
        `Bei Bedarf kannst du zudem zusätzlich im Einzelunterricht mit einem professionellen Repetitor\nsystematisch oder punktuell dein verständnis vertiefen,\nim Klausurtraining die gutachterliche Fallbearbeitung und Sachverhaltsarbeit lernen,\nund Fragen jeder Art stellen, die dir in der Arbeit mit der App oder im Selbststudium begegnet sind.`,
      ],
      abstract:
        "Erhalte Empfehlungen oder buche Einzelunterricht bei einem Repetitor, um Wissen zu vertiefen, Lücken zu schließen und dein Klausurenkönnen zu verbessern.",

      videoEmbedId: "1K0oz1oIick",
    },
  },
};
