import { useEffect } from "react";
import useStore from "@hooks/useStore";
import { useCookies } from "react-cookie";
import Script from "next/script";

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
    heap: any | undefined;
  }
}

function Analytics() {
  const cookieChoiceUpdated = useStore((state) => state.cookieChoiceUpdated);

  const [cookies] = useCookies(["acceptStatistic"]);

  // the analyticScriptIsLoaded stores whether or not we already loaded the script to
  // prevent a second time load of it
  const analyticScriptIsLoaded = useStore(
    (state) => state.analyticScriptIsLoaded
  );
  const set = useStore((state) => state.set);

  // Load Heap.io - if set as provider
  useEffect(() => {
    // Handles the Analytics script loading for Heap.io

    if (process.env.NEXT_PUBLIC_ANALYTICS_PROVIDER !== "heap") return;

    // This effect gets triggered when the user made an interaction with the consent buttons
    // of the Cookie Banner

    // We are not collecting data if we are developing on localhost
    // if (window.location.hostname === "localhost") return;

    // Get our Cookie to determine whether the user agreed to the statistical tracking or not
    const statisticCookie = cookies.acceptStatistic;

    // Function to load the heap script from inside a component
    const appendAnalyticsScript = () => {
      const script = document.createElement("script");
      script.id = "heap-analytics";
      script.type = "text/javascript";
      script.innerHTML = `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};heap.load(${process.env.NEXT_PUBLIC_HEAP_ANALYTICS_ID});`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        set((state) => {
          state.analyticScriptIsLoaded = true;
        });
      };
      document.head.appendChild(script);
    };

    // // Function to set options for a fully working analytics
    // const setFullAnalyticsWithConsent = () => {
    //   console.log("Setting analytics to full");
    //   window.heap = window.heap || [];
    //   window.heap.load(process.env.NEXT_PUBLIC_HEAP_ANALYTICS_ID);
    //   window.heap.identify({
    //     anonymous: false,
    //   });
    // };

    // // Function to set options for a limited working and anonymous analytics
    // const setAnonymousAnalytics = () => {
    //   console.log("Setting analytics to anonymous");
    //   // window.heap = window.heap || [];
    //   // window.heap.load(process.env.NEXT_PUBLIC_HEAP_ANALYTICS_ID);
    //   // window.heap.identify({
    //   //   anonymous: true,
    //   // });
    // };

    // Function to delete all the cookies that are set by Heap.io
    const deleteHeapCookies = () => {
      console.log("Deleting Heap cookies");
      // Get all cookies that start with _hp2_
      const heapCookies = document.cookie
        .split("; ")
        .filter((row) => row.startsWith("_hp2_"));

      // Delete the cookies by setting the max-age to 1 second
      if (heapCookies.length > 0) {
        console.log("Heap cookies found", heapCookies);
        heapCookies.forEach((cookie) => {
          document.cookie = `${cookie}; Secure;max-age=1`;
        });
      } else {
        console.log("No Heap _hp2_ cookies found");
      }

      console.log("document.cookie", document.cookie);
      const heapCookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith("heap"));

      // Delete the cookie by setting the max-age to 1 second
      if (heapCookie) {
        document.cookie = `${heapCookie}; Secure;max-age=1`;
      } else {
        console.log("No Heap heap cookie found");
      }
    };

    // Skip if we do not have a valid Heap ID
    if (
      process.env.NEXT_PUBLIC_HEAP_ANALYTICS_ID === undefined ||
      process.env.NEXT_PUBLIC_HEAP_ANALYTICS_ID === ""
    ) {
      return;
    }

    let timeout = null;
    // Load the script if it is not loaded yet
    if (
      !analyticScriptIsLoaded &&
      statisticCookie &&
      statisticCookie === "true"
    ) {
      // append the script after 5 seconds
      timeout = setTimeout(() => {
        appendAnalyticsScript();
      }, 5000);
    } else if (!statisticCookie || statisticCookie === "false") {
      // Delete the cookies if the user does not want to be tracked
      deleteHeapCookies();
    }

    // Optionally we can use a different version of the analytics, e.g. identify the user as anonymous
    // if (statisticCookie && statisticCookie === "true") {
    //   // we can use the analytics fully
    //   setFullAnalyticsWithConsent();
    // } else {
    //   // we only use an restricted and anonymous version of the tracking
    //   setAnonymousAnalytics();
    //   // the gtag.js script does not delete the cookies on a change of consent
    //   // therefore we will do it on our own
    //   deleteHeapCookies();
    // }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [cookieChoiceUpdated]);

  // Load Google Analytics and Google Ads - if set as provider
  useEffect(() => {
    // Handles the Analytics script loading for Google Analytics and Google Ads

    if (process.env.NEXT_PUBLIC_ANALYTICS_PROVIDER !== "google") return;

    // This effect gets triggered when the user made an interaction with the consent buttons
    // of the Cookie Banner

    // We are not collecting data if we are developing on localhost
    if (window.location.hostname === "localhost") return;

    // Get our Cookie to determine whether the user agreed to the statistical tracking or not
    const statisticCookie = cookies.acceptStatistic;
    // Function to load the gtag script from inside a component
    const appendAnalyticsScript = () => {
      const script = document.createElement("script");
      script.id = "google-analytics";
      script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_GID}`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        set((state) => {
          state.analyticScriptIsLoaded = true;
        });
      };
      document.body.append(script);
    };

    // Function to set options for a fully working analytics
    const setFullAnalyticsWithConsent = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag(...args: any) {
        window.dataLayer.push(args);
      }
      gtag("consent", "update", {
        analytics_storage: "granted",
        ad_storage: "granted",
      });
      gtag("set", "url_passthrough", true);
      gtag("set", { restricted_data_processing: false });

      gtag("js", new Date());

      gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_GID, {
        anonymize_ip: false,
      });
      gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ADS_ID, {
        anonymize_ip: false,
      });
    };

    // Function to set options for a limited working and anonymous analytics
    const setAnonymousAnalytics = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag(...args: any) {
        window.dataLayer.push(args);
      }
      // Set a default the first time
      if (cookieChoiceUpdated === 0) {
        gtag("consent", "default", {
          ad_storage: "denied",
          analytics_storage: "denied",
        });
      } else {
        gtag("consent", "update", {
          ad_storage: "denied",
          analytics_storage: "denied",
        });
      }
      gtag("set", { restricted_data_processing: true });
      // gtag("set", "url_passthrough", true);
      gtag("js", new Date());

      gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_GID, {
        anonymize_ip: true,
      });
    };

    // Function to delete all the cookies that are set by Google Analytics V4
    const deleteGoogleAnalyticsCookies = () => {
      const ga_Cookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith("_ga_"));
      if (ga_Cookie) {
        document.cookie = `${ga_Cookie}; Secure;max-age=1`;
      }
      const gaCookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith("_ga"));
      if (gaCookie) {
        document.cookie = `${gaCookie}; Secure;max-age=1`;
      }
      const gidCookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith("_gid"));
      if (gidCookie) {
        document.cookie = `${gidCookie}; Secure;max-age=1`;
      }
      const gac_gbCookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith("_gac_gb_"));
      if (gac_gbCookie) {
        document.cookie = `${gac_gbCookie}; Secure;max-age=1`;
      }
    };

    // Skip if the Google Analytics ID is undefined (Format it G-XXXXXX)
    if (
      process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_GID === undefined ||
      process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_GID === "" ||
      process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_GID === "G-XXXXXX"
    ) {
      return;
    }
    let timeout = null;
    // Load the script if it is not loaded yet
    if (!analyticScriptIsLoaded) {
      // append the script after 5 seconds
      timeout = setTimeout(() => {
        appendAnalyticsScript();
      }, 5000);
    }
    if (statisticCookie && statisticCookie === "true") {
      // we can use the analytics fully
      setFullAnalyticsWithConsent();
    } else {
      // we only use an restricted and anonymous version of the tracking
      setAnonymousAnalytics();
      // the gtag.js script does not delete the cookies on a change of consent
      // therefore we will do it on our own
      deleteGoogleAnalyticsCookies();
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookieChoiceUpdated]);

  return (
    <>
      {process.env.NEXT_PUBLIC_ANALYTICS_PROVIDER === "plausible" && (
        <>
          <Script
            async
            defer
            data-domain={process.env.NEXT_PUBLIC_PLAUSIBLE_DOMAIN}
            src="https://plausible.io/js/script.js"
          />
          <Script
            id="next-analytics-init"
            dangerouslySetInnerHTML={{
              __html: `window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }`,
            }}
          />
        </>
      )}
    </>
  );
}

export default Analytics;
