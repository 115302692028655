import { firestore } from "./firebase-init";
import { doc, runTransaction } from "firebase/firestore";
import { round } from "lodash";

import { ActiveDeck, StudySession, Step } from "@types";
import { getElapsedTime } from "@utils/getElapsedTime";

export interface StudySessionFirebase extends StudySession {
  createdTimestamp: number;
  date: string;
}

export const saveStudySession = async (
  {
    activeDeck,
    lastActiveTimestamp,
    userId,
  }: {
    activeDeck: ActiveDeck;
    lastActiveTimestamp: number;
    userId: string;
  },
  {
    addProgressCount,
  }: {
    addProgressCount?: boolean;
  } = {}
): Promise<StudySessionFirebase> => {
  const currentDate = new Date().toISOString().slice(0, 10);

  // Check if a session for that deck for today for that user already exists
  const sessionRef = doc(
    firestore,
    "userProgress",
    userId,
    "studySessions",
    `${activeDeck.id}-${currentDate}`
  );

  const deckRef = doc(
    firestore,
    "userProgress",
    userId,
    "forTopics",
    activeDeck.topicId,
    "forChapters",
    activeDeck.chapterId,
    "forDecks",
    activeDeck.id
  );

  let udpatedSession: StudySessionFirebase;

  // Add / update the session data in a single transaction
  await runTransaction(firestore, async (transaction) => {
    const sessionDoc = await transaction.get(sessionRef);
    const deckDoc = await transaction.get(deckRef);

    const elapsedTime = getElapsedTime(lastActiveTimestamp);

    if (sessionDoc.exists()) {
      // Add one interaction to the current step in the session
      const existingData = sessionDoc.data();

      const newItemProgress = {
        ...existingData.itemsProgress,
        [activeDeck.step]:
          (existingData.itemsProgress?.[activeDeck.step] || 0) + 1,
      };

      const updateData: Partial<StudySessionFirebase> = {
        // Always include fields that are unconditionally updated
        stepTimes: {
          ...existingData.stepTimes,
          [activeDeck.step]: parseFloat(
            (
              (existingData.stepTimes?.[activeDeck.step] || 0) + elapsedTime
            ).toFixed(2)
          ),
        },
      };

      // Conditionally add fields to the update
      if (addProgressCount) {
        updateData.itemsProgress = newItemProgress;
      }

      await transaction.update(sessionRef, updateData);

      udpatedSession = {
        ...(existingData as StudySessionFirebase),
        ...updateData,
        // Reset the last active timestamp
        lastActiveTimestamp: Date.now(),
      };
    } else {
      console.log("Session does not exist yet, creating a new one.");
      // Create a new session

      const newSession: StudySessionFirebase = {
        deckId: activeDeck.id,
        itemsProgress: {
          1: 0,
          2: 0,
          3: 0,
        },
        stepTimes: {
          1: 0,
          2: 0,
          3: 0,
        },
        // TODO: Check types
        currentStep: activeDeck.step as Step,
        deckInfo: activeDeck,
        createdTimestamp: Date.now(),
        date: currentDate,
      };

      // Always the elapsed time to the active step in the session
      newSession.stepTimes[activeDeck.step] = elapsedTime;

      // Already add the first interaction to the current step, if needed
      if (addProgressCount) {
        newSession.itemsProgress[activeDeck.step] = 1;
      }

      await transaction.set(sessionRef, newSession);

      udpatedSession = {
        ...newSession,
        lastActiveTimestamp: Date.now(),
      };
    }

    // Update the deck document with the elapsed time
    const deckData = deckDoc.data() || {};
    const newDeckStepTimes = {
      ...deckData.stepTimes,
      [activeDeck.step]: round(
        (deckData.stepTimes?.[activeDeck.step] || 0) + elapsedTime,
        2
      ),
    };

    // Optional improvement: Make sure times are aggregated across chapters and topics as well
    // (currently only done when handling handling card or question progress, not study sessions)

    await transaction.set(
      deckRef,
      { stepTimes: newDeckStepTimes },
      { merge: true }
    );
  });

  return udpatedSession;
};
