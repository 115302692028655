import { useCallback } from "react";
import {
  ArrowUpRightIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { useRouter } from "next/router";

import { CardWithRef } from "@types";
import Modal from "./Modal";
import Spinner from "./Spinner";
import Badge from "./Badge";
import Annotation from "./Annotation";
import ReaderCard from "@components/app/ReaderPage/ReaderCard";
import MiniBreadcrumbs from "@components/app/SearchPalette/MiniBreadcrumbs";
import ProBadge from "@components/app/Upgrade/ProBadge";
import useStore from "@hooks/useStore";
import { useLinkedCards } from "@hooks/firebase/useLinkedCards";
import { getIsProBlocked } from "@utils/getIsProBlocked";

const LinkPeek = () => {
  const { linkPeek, closeLinkPeek, user } = useStore((state) => ({
    linkPeek: state.linkPeek,
    closeLinkPeek: state.closeLinkPeek,
    user: state.user,
  }));
  const router = useRouter();

  // Get get the document references from the tag
  const [linkedCards, loading] = useLinkedCards(linkPeek.tag);

  const isPro = !getIsProBlocked(true, user);

  const handleOpenContext = (cards: CardWithRef[]) => {
    if (!isPro) {
      handleClose();
      router.push("/upgrade");
      return;
    }
    cards?.forEach((card) => {
      console.log("Card to open", card, card.ref.path);
      // Get topicId, chapterId, deckId and cardId from the card
      const topicId = card.ref.path.split("/")[1];
      const chapterId = card.ref.path.split("/")[3];
      const deckId = card.ref.path.split("/")[5];
      const cardId = card.ref.path.split("/")[7];

      // Open the context
      window.open(
        `/nachschlagen/${topicId}/${chapterId}/${deckId}?cardId=${cardId}`,
        "_blank"
      );
    });
  };

  const handleClose = useCallback(() => {
    closeLinkPeek();
  }, [closeLinkPeek]);

  // Create title (replace _ with space, create plural if more than one card, capitalize first letter)
  const tagDisplay = linkPeek.tag?.replace(/_/g, " ");
  const title = `Referenz${linkedCards?.length > 1 ? "en" : ""} zu "${
    tagDisplay?.charAt(0).toUpperCase() + tagDisplay?.slice(1)
  }"`;

  return (
    <Modal
      key={linkPeek.tag}
      size="large"
      type="link"
      open={linkPeek.open}
      title={
        <span className="inline-flex w-full items-center">
          {title}
          <Annotation
            className="ml-auto inline-block cursor-pointer"
            content={
              <div className="font-normal">
                Beachte, dass Verlinkungen aus unterschiedlichen Rechtsgebieten
                und -kontexten stammen können. Wir verlinken bewusst umfassend,
                um mit verschiedenen Perspektiven das Systemverständnis zu
                stärken. Feedback kannst du über den Button rechts unten im
                Lernelement geben.
              </div>
            }
          >
            <Badge className="my-0 ml-2" type="neutral">
              <InformationCircleIcon
                className="mr-1 h-4 w-4"
                aria-hidden="true"
              />
              Hinweis zu Verlinkungen
            </Badge>
          </Annotation>
        </span>
      }
      setClose={handleClose}
      onConfirm={() => {
        // If the user is not pro, open the upgrade modal
        // TODO: Handle cards from different topics (some blocked some now)
        if (!isPro) {
          router.push("/upgrade");
          return;
        }
        handleOpenContext(linkedCards);
      }}
      confirmButtonText={
        <span className="whitespace-nowrap">
          Alle im Kontext ansehen {!isPro && "(Pro)"}
        </span>
      }
    >
      <div>
        {loading ? (
          <div className="flex h-60 w-full items-center justify-center">
            <Spinner className="h-8 w-8" />
          </div>
        ) : (
          <div className="space-y-3">
            {linkedCards?.map((card) => (
              <div key={card.id}>
                <div
                  onClick={() => handleOpenContext([card])}
                  className="mb-1 block cursor-pointer flex-wrap items-center justify-between md:mx-2 md:flex"
                >
                  <MiniBreadcrumbs
                    hit={{
                      ...card,
                      objectID: card.id,
                      topicTitle: card.pathInfo.topicTitle,
                      chapterTitle: card.pathInfo.chapterTitle,
                      deckTitle: card.pathInfo.deckTitle,
                      firebasePath: card.ref.path,
                    }}
                  />
                  {getIsProBlocked(true, user, card.topicKey) && (
                    <ProBadge className="ml-auto mr-2" />
                  )}
                  <ArrowUpRightIcon className="h-4 w-4 " aria-hidden="true" />
                </div>
                <ReaderCard key={card.id} card={card} />
              </div>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default LinkPeek;
