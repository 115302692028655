import { useSanitized } from "@hooks/useSanizied";

/**
 * Component to render HTML text with sanitization
 * @param param0 HTML text to render as a string
 * @returns Sanitized HTML text as a span element
 */
const HTMLText = ({
  htmlString,
  ...rest
}: {
  htmlString: string;
  [key: string]: any;
}) => {
  const sanitized = useSanitized(htmlString);
  return <span dangerouslySetInnerHTML={sanitized} {...rest} />;
};

export default HTMLText;
