import {
  browserLocalPersistence,
  onAuthStateChanged,
  initializeAuth,
} from "firebase/auth";
import { collection, getDocs } from "firebase/firestore";
import { firebaseApp, firestore } from "./firebase-init";

import { ExtendedFirebaseUser } from "@types";

import { addUserProperties, identify, resetIdentity } from "@utils/heapEvents";
import useStore from "@hooks/useStore";

export const auth = initializeAuth(firebaseApp, {
  persistence: browserLocalPersistence,
});

onAuthStateChanged(auth, async (user) => {
  try {
    if (user) {
      // Get token for user claims
      const getIdTokenResult = await user.getIdTokenResult();

      // Get inital settings (-> after this, also listeners are set up in _app.tsx)
      const settingsQuerySnap = await getDocs(
        collection(firestore, "customers", user.uid, "appSettings")
      );
      const settings = settingsQuerySnap.docs.reduce(
        (acc, doc) => ({
          ...acc,
          [doc.id]: doc.data(),
        }),
        {}
      );

      useStore.setState({
        user: {
          ...user,
          claims: getIdTokenResult.claims,
          settings: settings,
        } as ExtendedFirebaseUser,
      });
      identify(user.uid);
      addUserProperties({
        stripeRole: getIdTokenResult.claims.stripeRole,
      });
    } else {
      useStore.setState({ user: undefined });
      resetIdentity();
    }
  } catch (error) {
    console.log(error);
  }
});

async function connectEmulators() {
  // lazy load connection to emulators

  if (
    typeof window !== "undefined" &&
    window.location.hostname === "localhost" &&
    process.env.NEXT_PUBLIC_USE_FIREBASE_EMULATORS === "true"
  ) {
    const { connectAuthEmulator } = await import("firebase/auth");
    connectAuthEmulator(auth, "http://localhost:9099", {
      disableWarnings: true,
    });
  }
}
connectEmulators();
