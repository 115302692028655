import React, { useState } from "react";

import Modal from "@components/shared/Modal";
import useStore from "@hooks/useStore";
import {
  ContentFeedbackFormData,
  saveContentFeedback,
} from "@utils/firebase/saveContentFeedback";
import VisualBreadcrumbs from "@components/shared/VisualBreadcrumbs";
import { toast } from "react-hot-toast";
import { useRouter } from "next/router";

const ContentFeedbackModal = () => {
  const {
    user,
    showContentFeedbackModal: { open, reference },
    set,
    activeDeck,
  } = useStore((state) => ({
    user: state.user,
    showContentFeedbackModal: state.showContentFeedbackModal,
    set: state.set,
    activeDeck: state.activeDeck,
  }));

  const router = useRouter();

  const [formData, setFormData] = useState<ContentFeedbackFormData>({
    feedback: "",
    feedbackType: "suggestion",
  });

  // update form data when inputs change
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const closeModal = () => {
    setFormData({
      feedback: "",
      feedbackType: "suggestion",
    });
    set(() => ({
      showContentFeedbackModal: {
        open: false,
        reference: null,
      },
    }));
  };

  const handleSubmit = () => {
    // Get location info from router
    const locationInfo = {
      deckId: router.query.params?.[2],
      chapterId: router.query.params?.[1],
      topicId: router.query.params?.[0],
    };

    if (!activeDeck || !user || !reference) return;
    saveContentFeedback({
      ...formData,
      userId: user.uid,
      userEmail: user.email,
      itemType: reference.itemType,
      reference: {
        ...locationInfo,
        itemId: reference?.itemId,
        question: reference?.question,
        // Conditionally add step and cardTitle if they exist
        ...(activeDeck?.step && { step: activeDeck?.step }),
        ...(reference?.cardTitle && { cardTitle: reference?.cardTitle }),
      },
    }).then(() => {
      toast.success("Vielen Dank! Dein Feedback wurde gesendet.");
    });
    closeModal();
  };

  return (
    <Modal
      open={open}
      // TODO: Handle custom closing
      setClose={closeModal}
      title={`Inhaltliches Feedback`}
      size="large"
      onConfirm={handleSubmit}
      confirmButtonText="Senden"
      cancelButtonText="Abbrechen"
    >
      <div className="space-y-6">
        {activeDeck && (
          <div>
            <VisualBreadcrumbs
              steps={[
                { name: activeDeck.topicTitle },
                { name: activeDeck.chapterTitle },
                { name: activeDeck.title },
              ]}
            />
            <div className="mt-1 text-center text-base font-semibold leading-5 text-slate-900 dark:text-slate-200 md:text-start">
              {activeDeck.step === 2
                ? reference?.question || reference?.cardTitle
                : reference?.cardTitle || reference?.question}
            </div>

            <div className="mt-5 border-b border-slate-200 dark:border-slate-600" />
          </div>
        )}
        <fieldset>
          <legend className="contents font-semibold text-slate-900 dark:text-slate-200">
            Art des Feedbacks
          </legend>
          <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
            Welche Art von Feedback möchtest du uns geben?
          </p>
          <div className="mt-2 flex flex-wrap gap-2 px-1 md:gap-10">
            <div className="flex items-center">
              <RadioInput
                label="Verbesserungsvorschlag"
                name="feedbackType"
                value="suggestion"
                checked={formData.feedbackType === "suggestion"}
                onChange={handleInputChange}
                inputId="type-suggestion"
              />
            </div>
            <div className="flex items-center">
              <RadioInput
                label="Verständnis"
                name="feedbackType"
                value="understanding"
                checked={formData.feedbackType === "understanding"}
                onChange={handleInputChange}
                inputId="type-understanding"
              />
            </div>
            <div className="flex items-center">
              <RadioInput
                label="Fehler / Ungenauigkeit"
                name="feedbackType"
                value="mistake"
                checked={formData.feedbackType === "mistake"}
                onChange={handleInputChange}
                inputId="type-mistake"
              />
            </div>
            <div className="flex items-center">
              <RadioInput
                label="Sonstiges"
                name="feedbackType"
                value="other"
                checked={formData.feedbackType === "other"}
                onChange={handleInputChange}
                inputId="type-other"
              />
            </div>
          </div>
        </fieldset>
        <div className="sm:col-span-6">
          <label
            htmlFor="feedback"
            className="block text-sm font-semibold text-slate-900 dark:text-slate-100"
          >
            Dein Feedback
          </label>
          <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
            Was möchtest du uns mitteilen? Wir freuen uns über Kritik und
            Vorschläge.
          </p>
          <div className="mt-2">
            <textarea
              id="feedback"
              name="feedback"
              rows={6}
              className="block w-full rounded-md border-slate-300  shadow-sm ring-inset focus:border-orange-500 focus:ring-orange-500 dark:border-slate-600 dark:bg-gray-700 dark:focus:border-orange-500 sm:text-sm"
              defaultValue=""
              value={formData.feedback}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const RadioInput = ({
  label,
  name,
  value,
  checked,
  onChange,
  inputId,
}: {
  label: string;
  name: string;
  value: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputId: string;
}) => {
  return (
    <>
      <input
        id={inputId}
        name={name}
        type="radio"
        checked={checked}
        value={value}
        onChange={onChange}
        className="focus-ring h-4 w-4 cursor-pointer border-slate-300 text-orange-600"
      />
      <label
        htmlFor={inputId}
        className="ml-3 block text-sm font-medium text-slate-800 dark:text-slate-200"
      >
        {label}
      </label>
    </>
  );
};

export default ContentFeedbackModal;
