import { doc, setDoc } from "firebase/firestore";
import { firestore } from "@utils/firebase/firebase-init";

import { SettingsCategory } from "@types";

export const updateUserSettings = async (
  userId: string,
  settingsCollection: SettingsCategory,
  data: Record<string, any>
) => {
  // Update progress for car
  if (!userId) return;

  const docRef = doc(
    firestore,
    "customers",
    userId,
    "appSettings",
    settingsCollection
  );

  // Add userId to data to enable query and security rule for collectionGroup query
  await setDoc(docRef, data, { merge: true })
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error("Error writing user document: ", error);
      return false;
    });
};
