const isSSR = typeof window === "undefined";

export const track = (event: string, data?: Record<string, any>) => {
  console.log("About to track event", event);
  if (!isSSR && window && window.heap) {
    console.log("Tracking event", event);
    window.heap?.track(event, data);
  }
};

export const identify = (userId: string) => {
  console.log("About to identify user", userId);
  if (!isSSR && window && window.heap) {
    console.log("Identifying user", userId);
    window.heap?.identify(userId);
  }
};

export const resetIdentity = () => {
  if (!isSSR && window && window.heap) {
    window.heap?.resetIdentity();
  }
};

export const addUserProperties = (
  properties: Record<string, string | number>
) => {
  if (!isSSR && window && window.heap) {
    window.heap?.addUserProperties(properties);
  }
};
