import { BaseCard, ContentLimit } from "@types";
import algoliasearch from "algoliasearch/lite";

const searchClient = algoliasearch(
  "DF9EGJ705V",
  "b9b7967bfde73c96b01fc449b7fed917"
);

// Get different index name based on the environment (change also in .env for functions that create the index)
const index = searchClient.initIndex(
  process.env.NODE_ENV === "production" ? "prod_content" : "dev_content"
);

// Temporary interface for search hits
export interface SearchHit extends BaseCard {
  objectID: string;
  topicTitle: string;
  chapterTitle: string;
  deckTitle: string;
  topicKey: ContentLimit;
  isTrial: boolean;
  firebasePath: string;
  _highlightResult?: { [key: string]: { value: string } };
  _snippetResult?: { any };
  _rankingInfo?: { any };
  _distinctSeqID?: number;
  // Allow for any other property
  [key: string]: any;
}

export const performSearch = async (query: string): Promise<SearchHit[]> => {
  try {
    const response = await index.search(query, {
      hitsPerPage: 30,
      highlightPreTag: "<mark>",
      highlightPostTag: "</mark>",
    });
    return response.hits as unknown as SearchHit[];
  } catch (error) {
    console.error(error);
    return [];
  }
};
