import { ChevronRightIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { FC } from "react";

const demoSteps = [
  { name: "Projects" },
  { name: "Project Nero" },
  { name: "Project NeBro" },
];

export interface VisualBreadcrumbsProps {
  steps: {
    name: string;
  }[];
}

const VisualBreadcrumbs: FC<VisualBreadcrumbsProps> = ({
  steps = demoSteps,
}) => {
  return (
    <div className="hidden sm:flex" aria-label="Breadcrumb">
      <ol role="list" className="flex flex-wrap items-center gap-1">
        {/* First Item */}
        <li>
          <div>
            <span
              className={clsx(
                "text-sm font-medium text-slate-500 dark:text-slate-400"
              )}
            >
              {steps?.[0].name}
            </span>
          </div>
        </li>
        {/* All other Items */}
        {steps.slice(1).map((step) => (
          <li key={step.name}>
            <div className="flex items-center">
              <ChevronRightIcon
                className="h-5 w-5 flex-shrink-0 text-slate-400"
                aria-hidden="true"
              />
              <span
                className={clsx(
                  "ml-1 text-sm font-medium text-slate-500 dark:text-slate-400"
                )}
              >
                {step.name}
              </span>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default VisualBreadcrumbs;
