import React, { FC, useState } from "react";
import { usePopper } from "react-popper";
import { motion, AnimatePresence } from "framer-motion";

export type AnnotatiopnPlacement =
  | "top-start"
  | "top"
  | "top-end"
  | "bottom-start"
  | "bottom"
  | "bottom-end"
  | "right-start"
  | "right"
  | "right-end"
  | "left-start"
  | "left"
  | "left-end";

export interface AnnotationProps {
  children: React.ReactNode;
  content: React.ReactNode;
  className?: string;
  placement?: AnnotatiopnPlacement;
  showDelay?: number;
  hideDelay?: number;
}

const Annotation: FC<AnnotationProps> = ({
  children,
  content,
  className,
  placement = "top-start",
  showDelay = 200, // default delay of 200ms
  hideDelay = 100, // default delay of 100ms
}) => {
  const [show, setShow] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  // const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    modifiers: [
      // { name: "arrow", options: { element: arrowElement } },
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  let timeoutId = null;

  const handleMouseEnter = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setShow(true);
    }, showDelay);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setShow(false);
    }, hideDelay);
  };

  return (
    <>
      <div
        className={className}
        ref={setReferenceElement}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>

      {/* Optional: Animate apprearance (headless.ui / framer motion) */}
      <AnimatePresence>
        {show && (
          <motion.div
            key="annotation-content"
            ref={setPopperElement}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.05 }}
            className="z-10 max-w-sm rounded border border-slate-200 bg-white/90 py-2 px-3 text-sm text-slate-800 dark:border-slate-600 dark:bg-slate-800/90 dark:text-slate-100"
            style={styles.popper}
            {...attributes.popper}
          >
            {content}
            {/* Currently no arrow used -> Otherwise style arrow in CSS according to positioning attribute: https://popper.js.org/docs/v2/tutorial/#arrow */}
            {/* <div
            ref={setArrowElement}
            style={styles.arrow}
          /> */}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Annotation;
